import OrderModel from "modules/orders/models/OrderModel";
import ProjectCategoryModel from "modules/projects/models/categories/ProjectCategoryModel";
import ProjectModel from "modules/projects/models/ProjectModel";
import Model from 'utils/Model'

class ProjectOrderModel extends Model {

    initialize(props) {
        super.initialize(props);
        this.orderId = props.orderId;
        this.projectId = props.projectId || null;
        this.order = new OrderModel(props.order || {});
        this.category = new ProjectCategoryModel(props.category || {});
        this.comment = props.comment || '';
    }


    getOrderUrl() {
        return this.order.getUrl();
    }

}

export default ProjectOrderModel
