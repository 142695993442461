export default {
    'title': {
        ru: 'Синхронизация',
        en: 'Synchronization',
    },
    'synchronization': {
        ru: 'Синхронизация',
        en: 'Synchronization',
    },
    'toInSight': {
        ru: 'Отправить в InSight',
        en: 'Send to InSight',
    },
    'externalId': {
        ru: 'Внешний ID',
        en: 'External ID',
    },
    'success': {
        ru: 'Заказ успешно синхронизирован',
        en: 'Order synchronized',
    },
    'successInSight': {
        ru: 'Заказ успешно отправлен в InSight',
        en: 'Order sent to InSight',
    },
    'synchronize': {
        ru: 'Синхронизировать сейчас',
        en: 'Synchronize now',
    },
    'synchronizing': {
        ru: 'Синхронизация...',
        en: 'Synchronizing...',
    },
}