export default {
    'emptyList': {
        ru: 'Контрагент ещё не вносил платежи',
        en: 'Contractor hasn\'t yet made payments',
    },
    'type': {
        ru: 'Тип',
        en: 'Type',
    },
    'unused': {
        ru: 'Остаток',
        en: 'Unused',
    },
    'externalId': {
        ru: 'Внешний ID',
        en: 'External ID',
    },
    'uses': {
        ru: 'Разноска по заказам',
        en: 'Order assignments'
    }
}