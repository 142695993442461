import classNames from 'classnames';
import { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';

interface ButtonProps {
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    block?: boolean;
    xs?: boolean;
    small?: boolean;
    large?: boolean;
    link?: boolean;
    danger?: boolean;
    warning?: boolean;
    info?: boolean;
    primary?: boolean;
    success?: boolean;
    secondary?: boolean;
    light?: boolean;
    dark?: boolean;
}

const Button: FC<PropsWithChildren<ButtonProps> & ButtonHTMLAttributes<HTMLButtonElement>> = ({
    className = '',
    block = false,
    xs = false,
    small = false,
    large = false,
    link = false,
    danger = false,
    warning = false,
    info = false,
    primary = false,
    success = false,
    secondary = false,
    light = false,
    dark = false,
    type = 'button',
    children,
    ...buttonProps
}) => {
    const cls = classNames(
        'btn',
        { 'btn-block': block },
        { 'btn-xs': xs },
        { 'btn-sm': small },
        { 'btn-lg': large },
        { 'btn-danger': danger },
        { 'btn-warning': warning },
        { 'btn-success': success },
        { 'btn-info': info },
        { 'btn-primary': primary },
        { 'btn-link': link },
        { 'btn-secondary': secondary },
        { 'btn-light': light },
        { 'btn-dark': dark },
        className
    );
    return (
        <button type={type} className={cls} {...buttonProps}>
            {children}
        </button>
    );
};

export default Button;
