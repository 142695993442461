import Model from '../../../../utils/Model'
import OrderStatusModel from "./OrderStatusModel";
import UserModel from "../../../users/models/UserModel";


class OrderStatusChangeModel extends Model {

  static validators = {
  };

  initialize(props) {
    super.initialize(props);
    this.oldStatus = props.oldStatus ? new OrderStatusModel(props.oldStatus) : null;
    this.newStatus = new OrderStatusModel(props.newStatus);
    this.user = new UserModel(props.user);
  }

  getDate() {
    return this.createdAt ? this.createdAt.format('DD MMM YYYY HH:mm') : '';
  }

}

export default OrderStatusChangeModel
