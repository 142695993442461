// import libs
import { AppConfigContext } from 'layout/AppConfigContext';
import { useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { protect } from '../common/core/access-control';
import PrivateRoute from './Private';
import routes from './routes';

import GuardContext from '../common/core/access-control/GuardContext';
import Layout from '../layout';
import UserModel from '../modules/users/models/UserModel';

const AppRoutes = ({ permissions, user }) => {
    const config = useContext(AppConfigContext);

    const compiledRoutes = useMemo(() => {
        const returner = { private: [], public: [] };
        for (const route of routes) {
            if (route?.enabled !== undefined) {
                if (typeof route.enabled === 'function' && !route.enabled(config)) {
                    continue;
                } else if (!route.enabled) {
                    continue;
                }
            }

            if (route.auth) {
                if (
                    config.remote.dealerCabinet?.enabled &&
                    user &&
                    user.isFeatureEnabled(4) &&
                    !route.isForDealerCabinet
                ) {
                    continue;
                }

                if (route.guard) {
                    route.component = protect(route.guard)(route.component);
                }
            }

            const routeProps = {
                path: route?.path ?? null,
                element: <route.component />,
            };

            if (route.auth) {
                returner.private.push(routeProps);
            } else {
                returner.public.push(routeProps);
            }
        }

        return returner;
    }, [routes, config]);

    return (
        <BrowserRouter>
            <GuardContext.Provider value={permissions}>
                <Layout>
                    <Routes>
                        {compiledRoutes.public.map((route, i) => (
                            <Route key={i} {...route} />
                        ))}
                        <Route element={<PrivateRoute />}>
                            {compiledRoutes.private.map((route, i) => (
                                <Route key={i} {...route} />
                            ))}
                        </Route>
                    </Routes>
                </Layout>
            </GuardContext.Provider>
        </BrowserRouter>
    );
};

const mapStateToProps = (state) => {
    return {
        permissions: state.auth.permissions,
        user: state.auth.user ? new UserModel(state.auth.user) : state.auth.user,
    };
};

export default connect(mapStateToProps)(AppRoutes);
