export default {
    'required': {
        ru: 'Обязательное поле',
        en: 'Required field'
    },
    'number': {
        ru: 'Должно быть числом',
        en: 'Must be a number'
    },
    'integer': {
        ru: 'Должно быть целым числом',
        en: 'Must be an integer'
    },
    'minLength': {
        ru: min => `Должно быть не меньше ${min} символов`,
        en: min => `Must be at least ${min} symbols`
    },
    'maxLength': {
        ru: max => `Должно быть не больше ${max} символов`,
        en: max => `Must be no more than ${max} symbols`
    },
    'minNumber': {
        ru: min => `Должно быть не меньше ${min}`,
        en: min => `Must be at least ${min}`
    },
    'maxNumber': {
        ru: max => `Должно быть не больше ${max}`,
        en: max => `Must be no more than ${max}`
    },
}