import 'utils/sentry';

import 'bootstrap/dist/css/bootstrap.min.css';
import { authCheck } from 'modules/auth/store/actions';
import { FC } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './assets/scss/style.scss';
import App from './layout/App';
import * as serviceWorker from './serviceWorker';
import store from './store';

if (process.env.NODE_ENV !== 'development') {
    console.log = () => {};
}

store.dispatch(authCheck());

const render = (Component: FC) => {
    const root = ReactDOM.createRoot(document.getElementById('root')!);
    root.render(
        <Provider store={store}>
            <Component />
        </Provider>
    );
};

render(App);

if (module.hot) {
    module.hot.accept('./layout/App', () => {
        const NextApp = require('./layout/App').default;
        render(NextApp);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({});
