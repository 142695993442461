export default {
    'general': {
        ru: 'Основное',
        en: 'General',
    },
    'orders': {
        ru: 'Заказы',
        en: 'Orders',
    },
    'items': {
        ru: 'Предметы',
        en: 'Items',
    },
    'attachments': {
        ru: 'Вложения',
        en: 'Attachments',
    },
}