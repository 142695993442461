import Model from 'utils/Model';
import validators from 'utils/validators';
import { composeValidators } from 'utils/validators/compose';
import UserModel from '../users/models/UserModel';

class CommentModel extends Model {
    static validators = {
        text: composeValidators(validators.required),
    };

    initialize(props) {
        super.initialize(props);
        this.id = props.id;
        this.parentId = props.parentId || null;

        this.text = props.text || '';
        this.user = new UserModel(props.user);

        this.replies =
            props.replies && props.replies.length
                ? props.replies.map((comment) => new CommentModel(comment))
                : [];
    }
}

export default CommentModel;
