export default {
    bik: value => !value || /^[\d]{9}$/i.test(value) ? undefined : '9 цифр',
    kpp: value => !value || /^[\d]{9}$/i.test(value) ? undefined : '9 цифр',
    ogrn: value => !value || /^[\d]{13}$/i.test(value) ? undefined : '13 цифр',
    ogrnip: value => !value || /^[\d]{15}$/i.test(value) ? undefined : '15 цифр',
    inn: {
        physical: value => !value || /^[\d]{12}$/i.test(value) ? undefined : '12 цифр',
        organisation: value => !value || /^[\d]{10}$/i.test(value) ? undefined : '10 цифр',
    },
    okpo: {
        physical: value => !value || /^[\d]{10}$/i.test(value) ? undefined : '10 цифр',
        organisation: value => !value || /^[\d]{8}$/i.test(value) ? undefined : '8 цифр',
    },
    accountNumber: value => !value || /^[\d]{20}$/i.test(value) ? undefined : '20 цифр'
}


