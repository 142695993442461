import loadable from '@react-loadable/revised';
import LoadingComponent from 'common/loader';
import type { ApplicationRoute } from 'routes/routes';

const routes: ApplicationRoute[] = [
    {
        path: '/catalogs/my',
        exact: true,
        auth: true,
        enabled: (config: any) => config.remote.catalogs?.enabled ?? false,
        component: loadable({
            loader: () => import('./pages/my/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/catalogs/manage',
        exact: true,
        auth: true,
        enabled: (config) => config.remote.catalogs?.enabled ?? false,
        component: loadable({
            loader: () => import('./pages/list/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/catalogs/manage/create',
        exact: true,
        auth: true,
        enabled: (config) => config.remote.catalogs?.enabled ?? false,
        component: loadable({
            loader: () => import('./pages/create/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/catalogs/manage/:id/:section?',
        exact: true,
        auth: true,
        enabled: (config) => config.remote.catalogs?.enabled ?? false,
        component: loadable({
            loader: () => import('./pages/edit/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
];

export default routes;
