import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

function getNavLinkClass({ isActive }) {
    return ['nav-link', isActive ? 'active' : ''].join(' ');
}
// initialize component
const Link = ({ id = null, level = 0, item, onActiveChanged }) => {
    const [active, setActive] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (item.isActive) {
            setActive(item.isActive([], location));
        } else {
            setActive(
                item.exact
                    ? location.pathname === item.link
                    : location.pathname.startsWith(item.link)
            );
        }
    }, [item, location]);

    useEffect(() => {
        if (typeof onActiveChanged === 'function') {
            onActiveChanged(id, active);
        }
    }, [active]);

    const cls = classNames({ active: active });

    if (level === 0) {
        return (
            <li className={cls}>
                <NavLink className={getNavLinkClass} to={item.link} end={item.exact}>
                    <span className="pcoded-micon">
                        <i className={item.icon || 'feather icon-chevrons-right'} />
                    </span>
                    <span className="pcoded-mtext">{item.caption}</span>
                </NavLink>
            </li>
        );
    } else {
        return (
            <li className={cls}>
                <NavLink className={getNavLinkClass} to={item.link} end={item.exact}>
                    {item.caption}
                </NavLink>
            </li>
        );
    }
};

export default Link;
