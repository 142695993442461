import dayjs from 'dayjs';
import OrderModel from 'modules/orders/models/OrderModel';
import UserModel from 'modules/users/models/UserModel';
import Model from 'utils/Model';
import validators from 'utils/validators';
import { composeValidators } from 'utils/validators/compose';

class OrderClaimModel extends Model {
    static validators = {
        responsibleId: composeValidators(validators.integer),
        name: composeValidators(validators.required, validators.minLength(3)),
    };

    initialize(props) {
        super.initialize(props);

        this.type = props.type || {};
        this.orderId = props.orderId || null;
        this.closedAt = (props.closedAt && dayjs(props.closedAt)) || null;
        this.order = props.order ? new OrderModel(props.order) : new OrderModel();
        this.responsible = props.responsible ? new UserModel(props.responsible) : new UserModel();
        this.description = props.description || null;
        this.name = props.name || null;
        this.isClosed = props.isClosed || false;
        this.warnings = props.warnings || [];

        this.files = props.files && Array.isArray(props.files) ? props.files : [];
        this.filesCount = this.files.length;
    }
    getClosedDate() {
        return this.closedAt ? this.closedAt.format('DD.MM.YYYY HH:mm') : '';
    }
}

export default OrderClaimModel;
