import Model from 'utils/Model';
import validators from 'utils/validators';
import { composeValidators } from 'utils/validators/compose';
import CommunicationModel from './CommunicationModel';

class ContactModel extends Model {
    static validators = {
        name: composeValidators(validators.required),
    };

    initialize(props) {
        super.initialize(props);

        this.id = props.id;
        this.isPrimary = props.isPrimary !== undefined ? !!props.isPrimary : null;
        this.name = props.name || '';
        this.rank = props.rank || '';
        this.comment = props.comment || '';
        this.communications =
            props.communications && props.communications.length
                ? props.communications.map((item) => new CommunicationModel(item))
                : [];
    }
}

export default ContactModel;
