import { Tooltip } from 'antd';
import Notifications from 'layout/blocks/header/components/notifications/Notifications';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { localLogout } from '../../../modules/auth/service';
import { t } from '../../../translations';
import { isDebugAvailable, isDebugEnabled, toggleDebug } from '../../../utils/debug';
import FullScreen from '../../../utils/FullScreen';
import useConfig from '../../../utils/hooks/useConfig';
import { navbarMobileClose, navbarMobileOpen } from '../navbar/store/actions';
import Mobile from './components/brand/Mobile';
import UserMenu from './components/user-menu/UserMenu';

const Header = ({
    menuOpened = false,
    user,
    showRecentOrders = false,
    showNotifications = true,
    showHamburger = true,
    showHomeButton = false,
    showLogo = false,
    style,
}) => {
    const config = useConfig();
    const dispatch = useDispatch();
    const [debug, setDebug] = useState(isDebugEnabled());

    const logout = () => {
        dispatch(localLogout());
    };

    const toggleMenuOpen = () => {
        menuOpened ? dispatch(navbarMobileClose()) : dispatch(navbarMobileOpen());
    };

    const onToggleDebug = () => {
        toggleDebug();
        setDebug(isDebugEnabled());
    };

    const showDebug = isDebugAvailable();

    return (
        <header className="navbar pcoded-header navbar-expand-lg navbar-light" style={style}>
            {showLogo && (
                <Link to="/" className="b-brand header-logo-2">
                    <img
                        className="b-img"
                        src={`/logo/${config.remote.tenantConfig.logo.main}`}
                        alt="EasyOrder CRM"
                    />
                </Link>
            )}
            <Mobile
                collapsed={menuOpened}
                onCollapseClick={toggleMenuOpen}
                showHamburger={showHamburger}
            />
            <a className="mobile-menu" id="mobile-header">
                <i className="feather icon-more-horizontal" />
            </a>
            <div className="collapse navbar-collapse">
                <ul className="header-icons navbar-nav me-auto">
                    {showHomeButton && (
                        <li>
                            <Link to={'/'}>
                                <i className="feather icon-home" />
                            </Link>
                        </li>
                    )}
                    <li>
                        <a
                            onClick={() => {
                                FullScreen.toggleFullScreen();
                            }}
                            className="cr-pointer"
                        >
                            <i className="feather icon-maximize" />
                        </a>
                    </li>
                    {showDebug && (
                        <li>
                            <Tooltip title={debug ? 'Debug enabled' : 'Debug disabled'}>
                                <a
                                    onClick={onToggleDebug}
                                    className="debug debug-toggle cr-pointer"
                                >
                                    <i
                                        className="feather icon-github"
                                        style={{ color: debug ? 'green' : 'red' }}
                                    />
                                </a>
                            </Tooltip>
                        </li>
                    )}
                    {showRecentOrders && (
                        <li className="nav-item dropdown">
                            <a className="dropdown-toggle" href="#" data-toggle="dropdown">
                                {t('common.recentOrders')}
                            </a>
                            <ul className="dropdown-menu">
                                <li>
                                    <a className="dropdown-item" href="#!">
                                        Action
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#!">
                                        Another action
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#!">
                                        Something else here
                                    </a>
                                </li>
                            </ul>
                        </li>
                    )}
                </ul>
                <ul className="navbar-nav ms-auto">
                    {showNotifications && <Notifications />}
                    <UserMenu user={user} onLogout={logout} />
                </ul>
            </div>
        </header>
    );
};

export default Header;
