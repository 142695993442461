import { Badge } from 'antd';
import React from 'react';

export default function NotificationCounter({ count }) {
    return (
        <>
            {count > 0 && (
                <Badge
                    size="small"
                    count={count}
                    style={{ position: 'absolute', right: '-10px', top: '-20px' }}
                />
            )}
        </>
    );
}
