import Item from './Item';
import { t } from '../../../../../translations';
import { Guard } from '../../../../../common/core/access-control';

const Navigation = ({ items, level = 0 }) => {
    return (
        <ul className="nav pcoded-inner-navbar">
            {items.map((item, i) => {
                if (item.visible !== undefined && !item.visible) return '';
                const el = <Item key={i} item={item} />;
                if (item.guard !== undefined) {
                    return (
                        <Guard requirement={item.guard} key={i}>
                            {el}
                        </Guard>
                    );
                }
                return el;
            })}
        </ul>
    );
};
export default Navigation;
