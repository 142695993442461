export default {
    'general': {
        ru: 'Информация',
        en: 'Information',
    },
    'emails': {
        ru: 'Почтовые аккаунты',
        en: 'Mail accounts',
    },
    'security': {
        ru: 'Безопасность',
        en: 'Security',
    },
}