import CustomFieldModel from 'common/core/custom-fields/CustomFieldModel';
import dayjs from 'dayjs';
import Model from 'utils/Model';
import DocumentTemplateModel from '../documents/DocumentTemplateModel';

class CustomDocumentModel extends Model {
    initialize(props) {
        super.initialize(props);
        this.orderId = props.orderId;
        this.isLocked = props.isLocked;
        this.documentName = props.documentName || '-';
        this.templateId = props.templateId || null;
        this.template = new DocumentTemplateModel(props.template || {});
        this.fieldsMeta = props.fieldsMeta || {};
        this.fields = props.fields || {};
        this.availableActions = props.availableActions || [];
        this.availableFields = props.availableFields || [];
        for (const key in this.fields) {
            if (!this.fields.hasOwnProperty(key)) continue;
            if (this.getFieldType(key) === 'date') {
                this.fields[key] = dayjs(this.fields[key]);
            }
        }

        this.fieldsData = [];
        for (let key in this.availableFields) {
            if (this.availableFields.hasOwnProperty(key)) {
                this.fieldsData.push(new CustomFieldModel(this.availableFields[key]));
            }
        }
    }

    getCreatedAtDate() {
        return this.createdAt ? this.createdAt.format('DD MMM YYYY HH:mm') : '';
    }

    getFieldName(code) {
        if (this.fieldsMeta.hasOwnProperty(code)) {
            return this.fieldsMeta[code].name;
        }
        return null;
    }

    getFieldType(code) {
        if (this.fieldsMeta.hasOwnProperty(code)) {
            return this.fieldsMeta[code].type;
        }
        return null;
    }
}

export default CustomDocumentModel;
