import _ from 'lodash';
import PropTypes from 'prop-types';
import Group from './Group';
import Link from './Link';

const propTypes = {
    item: PropTypes.object.isRequired,
    level: PropTypes.number,
    onActiveChanged: PropTypes.func,
};

function Item({ item, level = 0, ...props }) {
    return _.isArray(item.items) ? (
        <Group item={item} level={level} {...props} />
    ) : (
        <Link item={item} level={level} {...props} />
    );
}

Item.propTypes = propTypes;

export default Item;
