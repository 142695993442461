import _ from 'lodash';
import OrderItemModel from 'modules/orders/models/items/OrderItemModel';
import AttachmentModel from 'modules/projects/models/attachments/AttachmentModel';
import ProjectCategoryModel from 'modules/projects/models/categories/ProjectCategoryModel';
import ProjectOrderModel from 'modules/projects/models/orders/ProjectOrderModel';
import validators from 'utils/validators';
import { composeValidators } from 'utils/validators/compose';
import Model from '../../../utils/Model';

import dayjs from 'dayjs';
import CommentModel from '../../comments/CommentModel';
import ContractorModel from '../../contractors/models/ContractorModel';
import ManagerModel from './managers/ManagerModel';

import CustomFieldModel from '../../../common/core/custom-fields/CustomFieldModel';

class ProjectModel extends Model {
    static validators = {
        name: composeValidators(validators.required),
    };

    initialize(props) {
        super.initialize(props);
        this.id = props.id;
        this.externalId = props.externalId || null;

        this.syncedAt = (props.syncedAt && dayjs(props.syncedAt)) || null;

        this.name = props.name || '';

        this.totalValueFormatted = props.totalValueFormatted || '';
        this.totalValue = props.totalValue || 0;

        this.comment = props.comment || '';

        this.contractor =
            props.contractor && props.contractor.id
                ? new ContractorModel(props.contractor)
                : new ContractorModel();

        this.comments =
            props.comments && props.comments.length
                ? props.comments.map((item) => new CommentModel(item))
                : [];

        this.orders =
            props.orders && props.orders.length
                ? props.orders.map((item) => new ProjectOrderModel(item))
                : [];

        this.setItems(props.items);

        this.setAttachments(props.attachments);

        this.setCategories(props.categories);

        this.ordersCount = props.ordersCount || 0;

        this.itemsCount = props.itemsCount || 0;

        this.attachmentsCount = props.attachmentsCount || 0;

        this.managers =
            props.managers && props.managers.length
                ? props.managers.map((item) => new ManagerModel(item))
                : [];

        this.customFields = props.customFields || {};

        const fieldsData = props.fieldsMeta || {};

        this.fieldsMeta = [];
        for (let key in fieldsData) {
            if (fieldsData.hasOwnProperty(key)) {
                this.fieldsMeta.push(new CustomFieldModel(fieldsData[key]));
            }
        }
    }

    setAttachments(files) {
        this.attachments = [];
        if (!Array.isArray(files)) return;

        this.attachments = files.map((f) =>
            f instanceof AttachmentModel ? f : new AttachmentModel(f)
        );
    }

    setItems(items) {
        this.items = [];
        this.itemsCount = 0;
        if (Array.isArray(items)) {
            this.items = items.map((i) =>
                i instanceof OrderItemModel ? i : new OrderItemModel(i)
            );
        }
        this.itemsCount = this.getProducts().length + this.getServices().length;
    }

    setOrders(orders) {
        this.orders = [];
        this.ordersCount = 0;
        if (Array.isArray(orders)) {
            this.orders = orders.map((o) =>
                o instanceof ProjectOrderModel ? o : new ProjectOrderModel(o)
            );
        }
        this.ordersCount = this.orders.length;
    }

    setCategories(categories) {
        this.categories = [];
        if (!Array.isArray(categories)) return;
        this.categories = categories.map((c) =>
            c instanceof ProjectCategoryModel ? c : new ProjectCategoryModel(c)
        );
        const extractOrders = (category, orders) => {
            for (let order of category.orders) {
                order.category = category;
                orders.push(order);
            }
            for (let child of category.children) {
                orders = extractOrders(child, orders);
            }

            return orders;
        };
        let orders = [];
        for (let category of this.categories) {
            orders = extractOrders(category, orders);
        }
        this.setOrders(orders);
    }

    getOrderCategory(orderId) {
        const seekOrder = (orderId, category) => {
            for (let o of category.orders) {
                if (o.orderId === orderId) return category;
            }
            for (let c of category.children) {
                const cat = seekOrder(orderId, c);
                if (cat) return cat;
            }
            return null;
        };

        for (let cat of this.categories) {
            const found = seekOrder(orderId, cat);
            if (found) return found;
        }
        return new ProjectCategoryModel();
    }

    getUrl() {
        return `/project/${this.id}`;
    }

    addComment(props) {
        if (props.parentId) {
            const _insert = (list, id, props) =>
                list.map((item) => {
                    if (item.id === id) item.replies.unshift(new CommentModel(props));
                    else if (item.replies && item.replies.length)
                        item.replies = _insert(item.replies, id, props);

                    return item;
                });
            this.comments = _insert(this.comments, props.parentId, props);
        } else {
            this.comments.unshift(new CommentModel(props));
        }
    }

    deleteComment(id) {
        const _delete = (list, id) =>
            list.filter((item) => {
                if (item.replies && item.replies.length) item.replies = _delete(item.replies, id);
                return item.id !== id;
            });
        this.comments = _delete(this.comments, id);
    }

    replaceComment(id, props) {
        const _replace = (list, id, props) =>
            list.map((item) => {
                if (item.id === id) return new CommentModel(props);
                if (item.replies && item.replies.length)
                    item.replies = _replace(item.replies, id, props);
                return item;
            });
        this.comments = _replace(this.comments, id, props);
    }

    getItems() {
        return _.filter(this.items, (i) => i.isItem);
    }

    getProducts() {
        return _.filter(this.items, (i) => i.type === 'item');
    }

    getServices() {
        return _.filter(this.items, (i) => i.type === 'service');
    }
}

export default ProjectModel;
