export default {
    'createOrder': {
        ru: 'Создать новый заказ',
        en: 'Create new order',
    },
    'number': {
        ru: 'Номер',
        en: 'Number',
    },
    'status': {
        ru: 'Статус',
        en: 'Status',
    },
    'value': {
        ru: 'Сумма',
        en: 'Value',
    },
}