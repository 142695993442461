/* ============
 * Transformer
 * ============
 *
 * The base transformer.
 *
 * Transformers are used to transform the fetched data
 * to a more suitable format.
 * For instance, when the fetched data contains snake_cased values,
 * they will be camelCased.
 */

import { getConfig } from 'config/config';
import { isDayjs } from 'dayjs';
import _ from 'lodash'; //TODO: remove!!

//TODO: get rid of this
export default class Transformer {
    static fetch(param, ignored = []) {
        if (param && Array.isArray(param)) {
            return Transformer.fetchCollection(param);
        }
        if (param && typeof param === 'object') {
            return Transformer.fetchObject(param, ignored);
        }

        return param;
    }

    /**
     * Method extracts meta information from headers
     *
     * @param headers
     * @return {currentPage, pageCount, totalCount, perPage}
     */

    static fetchMeta(headers) {
        return {
            currentPage: parseInt(headers['x-pagination-current-page']),
            pageCount: parseInt(headers['x-pagination-page-count']),
            totalCount: parseInt(headers['x-pagination-total-count']),
            perPage: parseInt(headers['x-pagination-per-page']),
        };
    }

    /**
     * Method used to transform a fetched data and fills meta information
     *
     * @param response
     * @return {*}
     */
    static fetchWithMeta(response) {
        return {
            data: Transformer.fetch(response.data),
            meta: Transformer.fetchMeta(response.headers),
        };
    }

    /**
     * Method used to transform errors
     *
     * @param err
     * @return {*}
     */
    static error(err) {
        if (!err.response) return err;
        const statusCode = err.response.status;
        const data = {
            error: null,
            statusCode,
        };

        if (statusCode === 422) {
            const resetErrors = {
                errors: err.response.data.errors,
                replace: false,
                searchStr: '',
                replaceStr: '',
            };
            data.error = Transformer.resetValidationFields(resetErrors);
            return data;
        } else if (statusCode === 401) {
            //data.error = err.response.data.message;
        }
        return err;
    }

    /**
     * Method used to transform a fetched collection
     *
     * @param param
     * @return [Array]
     */
    static fetchCollection(param) {
        return param.map((item) => Transformer.fetch(item));
    }

    static fetchObject(param, ignored = []) {
        const data = {};
        _.forOwn(param, (value, key) => {
            if (ignored.indexOf(key) !== -1) {
                data[key] = value;
            } else {
                data[_.camelCase(key)] = Transformer.fetch(value);
            }
        });
        return data;
    }

    /**
     * Method used to transform a send data into FormData
     *
     * @param param object
     * @return FormData
     */
    static sendAsFormData(param) {
        this.formData = new FormData();
        let elems = {};
        if (param && typeof param === 'object') {
            elems = Transformer.sendObject(param);
        }

        _.forOwn(elems, (value, key) => {
            this.formData.append(key, value);
        });
        return this.formData;
    }

    /**
     * Method used to transform a send data
     *
     * @param param
     * @return {*}
     */
    static send(param) {
        if (param && param instanceof File) {
            return param;
        }
        if (param && Array.isArray(param)) {
            return Transformer.sendCollection(param);
        }
        if (isDayjs(param)) {
            return param.format(getConfig().remote.tenantConfig.formats.date); //TODO: hacky way, definitely not a react way
        }
        if (param && typeof param === 'object') {
            return Transformer.sendObject(param);
        }

        return param;
    }

    /**
     * Method used to transform a collection to be send
     *
     * @param param
     * @return [Array]
     */
    static sendCollection(param) {
        return param.map((item) => Transformer.send(item));
    }

    /**
     * Method used to transform a object to be send
     *
     * @param param
     * @returns {{}}
     */
    static sendObject(param) {
        const data = {};

        _.forOwn(param, (value, key) => {
            data[_.snakeCase(key)] = Transformer.send(value);
        });
        return data;
    }

    /**
     * Method used to transform a form errors
     *
     * @param errors The fetched data
     * @param replace Boolean
     * @param searchStr String
     * @param replaceStr String
     * @returns {{}}
     */
    static resetValidationFields({ errors, replace = false, searchStr = '', replaceStr = '' }) {
        const data = {};

        _.forOwn(errors, (value, key) => {
            let index = '';
            if (replace) {
                index = _.camelCase(key.replace(searchStr, replaceStr));
            } else {
                index = _.camelCase(key);
            }
            if (Array.isArray(value)) {
                data[index] = _.head(Transformer.fetch(value));
            } else {
                data[index] = Transformer.fetch(value);
            }
            if (index === 'file') {
                data['raw_file'] = _.head(Transformer.fetch(value));
            }
        });
        return data;
    }
}
