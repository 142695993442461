// import libs
import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

//import components

const displayName = 'Menu collapse btn';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired
};


function MenuCollapse(props) {
  const cls = classNames(
    'cr-pointer',
    'mobile-menu',
    {'on': props.collapsed}
  );
  return <a className={cls} onClick={props.onClick}><span/></a>;
}

MenuCollapse.displayName = displayName;
MenuCollapse.propTypes = propTypes;

export default MenuCollapse