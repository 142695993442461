import { FC, PropsWithChildren, ReactNode, useContext } from 'react';

import GuardContext from './GuardContext';
import Requirement from './Requirement';

interface GuardProps {
    requirement: Requirement | Requirement[];
    fallback?: ReactNode;
}

const Guard: FC<GuardProps & PropsWithChildren> = ({ requirement, fallback = null, children }) => {
    const credentials = useContext(GuardContext);
    let requirements: Requirement[] = [];

    if (!Array.isArray(requirement)) {
        requirements = [requirement];
    } else {
        requirements = [...requirement];
    }

    let isSatisfied: boolean = false;
    for (const req of requirements) {
        isSatisfied = isSatisfied || req.isSatisfied(credentials);
    }

    return <>{isSatisfied ? children : fallback}</>;
};

export { Guard };
