import React from 'react';
import PropTypes from 'prop-types';

import PageHeader from "common/ui/page-header/PageHeader";
import Error from "./Error";

import {t} from 'translations';

const propTypes = {
    caption: PropTypes.string,
    title: PropTypes.string,
};
const defaultProps = {
    title: t('common.errors.oops'),
    caption: t('common.errors.pageError'),
};

const PageError = ({caption, title}) => (
    <div>
        <PageHeader title={title}/>
        <div className="main-body">
            <div className="page-wrapper">
                <div className="card">
                    <div className="card-block p-2">
                        <Error caption={caption}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

PageError.propTypes = propTypes;
PageError.defaultProps = defaultProps;

export default PageError;