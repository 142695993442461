import type { AppConfigType } from 'config/types';
import authRoutes from 'modules/auth/routes';
import catalogsRoutes from 'modules/catalogs/routes';
import contractorsRoutes from 'modules/contractors/routes';
import dashboardRoutes from 'modules/dashboard/routes';
import dealerCabinetRoutes from 'modules/dealer/routes';
import exchangeRoutes from 'modules/exchange/routes';
import licenseRoutes from 'modules/license/routes';
import ordersRoutes from 'modules/orders/routes';
import projectsRoutes from 'modules/projects/routes';
import regionsRoutes from 'modules/regions/routes';
import structureRoutes from 'modules/structure/routes';
import tasksRoutes from 'modules/tasks/routes';
import usersRoutes from 'modules/users/routes';
import webRoutes from 'modules/web/routes';
import { ComponentType } from 'react';

export interface ApplicationRoute {
    enabled?: boolean | ((config: AppConfigType) => boolean);
    path?: string;
    exact?: boolean;
    auth?: boolean;
    isForDealerCabinet?: boolean;
    component: ComponentType;
}

const routes: ApplicationRoute[] = [
    ...authRoutes,
    ...usersRoutes,
    ...dashboardRoutes,
    ...contractorsRoutes,
    ...exchangeRoutes,
    ...projectsRoutes,
    ...ordersRoutes,
    ...structureRoutes,
    ...tasksRoutes,
    ...licenseRoutes,
    ...catalogsRoutes,
    ...dealerCabinetRoutes,
    ...regionsRoutes,
    ...webRoutes, //IMPORTANT: Must be last in routes list
];
export default routes;
