//import libs
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";


class BreadCrumbs extends Component {
  // set name of the component
  static displayName = 'BreadCrumbs';

  // validate props
  static propTypes = {
    breadcrumbs: PropTypes.array,
    showHomeLink: PropTypes.bool,
    homeLink: PropTypes.object,
  };

  static defaultProps = {
    breadcrumbs: [],
    showHomeLink: false,
    homeLink: {
      link: '/',
      icon: 'feather icon-home'
    }
  };

  renderBreadcrumbCaption(bc) {
    return bc.icon ? <i className={bc.icon}/> : bc.caption;
  }

  renderBreadcrumb(bc) {
    return <li className="breadcrumb-item">
      {bc.link ?
        <Link to={bc.link}>
          {this.renderBreadcrumbCaption(bc)}
        </Link>
        :
        <a>
          {this.renderBreadcrumbCaption(bc)}
        </a>
      }
    </li>;
  }

  // render component
  render() {
    const {showHomeLink, homeLink} = this.props;

    const breadcrumbs = [...this.props.breadcrumbs];

    if(!breadcrumbs.length) return null;

    if(showHomeLink)
      breadcrumbs.unshift(homeLink);
    return (
      <ul className="breadcrumb">
        {breadcrumbs.map(bc => this.renderBreadcrumb(bc))}
      </ul>
    )
  }
}

export default BreadCrumbs
