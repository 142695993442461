export default {
    events: {
        ru: 'События',
        en: 'Events',
    },
    orders: {
        ru: 'События заказов',
        en: 'Order events',
    },
    order: {
        ru: 'Заказ',
        en: 'Order',
    },
    noEvents: {
        ru: 'Новых событий нет',
        en: 'No new events',
    },
};
