export default {
    navigation: {
        ru: 'Навигация',
        en: 'Navigation',
    },
    dashboard: {
        ru: 'Ситуация',
        en: 'Dashboard',
    },
    users: {
        ru: 'Пользователи',
        en: 'Users',
    },
    contractors: {
        ru: 'Контрагенты',
        en: 'Clients',
    },
    projects: {
        ru: 'Проекты',
        en: 'Projects',
    },
    orders: {
        ru: 'Заказы',
        en: 'Orders',
    },
    ordersList: {
        ru: 'Список',
        en: 'List',
    },
    ordersRealisations: {
        ru: 'Реализации',
        en: 'Realisations',
    },
    structure: {
        ru: 'Структура',
        en: 'Structure',
    },
    apiSpec: {
        ru: 'Спецификация API',
        en: 'API specification',
    },
    structureList: {
        ru: 'Список',
        en: 'List',
    },
    structureTree: {
        ru: 'Дерево',
        en: 'Tree',
    },
    license: {
        ru: 'Лицензия',
        en: 'License',
    },
    catalogs: {
        ru: 'Каталоги',
        en: 'Catalogs',
    },
    management: {
        ru: 'Управление',
        en: 'Management',
    },
    myCatalogs: {
        ru: 'Мои каталоги',
        en: 'My catalogs',
    },
    regions: {
        en: 'Regions',
        ru: 'Регионы',
    },
};
