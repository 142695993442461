import dayjs from 'dayjs';
import Model from '../../../utils/Model';

class EmailModel extends Model {
    initialize(props) {
        super.initialize(props);
        this.id = props.id;
        this.messageId = props.messageId || null;

        this.date = (props.date && dayjs(props.date)) || null;

        this.incoming = props.incoming || false;
        this.subject = props.subject || null;
        this.fromAddress = props.fromAddress || null;
        this.fromName = props.fromName || null;

        this.to = props.to && props.to.length ? props.to : [];
        this.cc = props.cc && props.cc.length ? props.cc : [];

        this.hasAttachments = !!props.hasAttachments;

        this.text = props.text || '';
        this.body = props.body || '';
    }

    getDate() {
        return this.date ? this.date.format('DD MMM YYYY HH:mm') : '';
    }
}

export default EmailModel;
