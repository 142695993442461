import loadable from '@react-loadable/revised';
import LoadingComponent from 'common/loader';
import type { ApplicationRoute } from 'routes/routes';

const routes: ApplicationRoute[] = [
    {
        path: '/users',
        exact: true,
        auth: true,
        component: loadable({
            loader: () => import('./pages/list/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/users/create',
        exact: true,
        auth: true,
        component: loadable({
            loader: () => import('./pages/create/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/user/:id',
        exact: true,
        auth: true,
        component: loadable({
            loader: () => import('./pages/edit/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/profile/:section?',
        exact: true,
        auth: true,
        isForDealerCabinet: true,
        component: loadable({
            loader: () => import('./pages/profile/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
];

export default routes;
