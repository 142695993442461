export default {
    'notCreated': {
        ru: 'Конфигурация не создана',
        en: 'Configuration wasn\'t created',
    },
    'configurator': {
        ru: 'Конфигуратор',
        en: 'Create',
    },
    'openIn2020': {
        ru: 'Открыть в 20-20',
        en: 'Open in 20-20',
    },
    'configuration': {
        ru: 'Конфигурация от',
        en: 'Configuration from',
    },
    'otherVersions': {
        ru: 'Другие версии',
        en: 'Other versions',
    },
    'emptyReports': {
        ru: 'Нет прикреплённых отчётов',
        en: 'Reports wasn\'t attached',
    },
    'startWithEmpty': {
        ru: 'Начать с пустого проекта',
        en: 'Start with empty project',
    },
    'regenerateReports': {
        ru: 'Перестроить отчёты',
        en: 'Regenerate reports',
    },
}