export default {
    'title': {
        ru: 'Менеджеры',
        en: 'Managers',
    },
    'emptyList': {
        ru: 'Нет ответственных менеджеров',
        en: 'Project hasn\'t attached managers',
    },
    'attaching': {
        ru: 'Привязка менеджера',
        en: 'Attach manager',
    },
    'editing': {
        ru: 'Редактирование менеджера',
        en: 'Edit manager',
    },
    'attach': {
        ru: 'Привязать',
        en: 'Attach',
    },
    'user': {
        ru: 'Пользователь',
        en: 'User',
    },
    'comment': {
        ru: 'Комментарий',
        en: 'Comment',
    },
    'detach': {
        ru: 'Отвязать',
        en: 'Detach',
    },
    'detachConfirm': {
        ru: 'Вы уверены, что хотите отвязать менеджера?',
        en: 'Are you sure you want to detach manager?',
    },
}