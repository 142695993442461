import Model from 'utils/Model'



class DocumentTemplateModel extends Model {

  initialize(props) {
    super.initialize(props);
    this.name = props.name || null;
  }
}

export default DocumentTemplateModel
