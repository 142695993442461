import 'dayjs/locale/ru';
import { AppConfigType, BackendConfigType, LocalConfigType } from './types';

const localConfig: LocalConfigType = {
    apiUrl: window.location.origin + '/api',
    apiTimeout: 5000,
};

export function buildConfig(remote: BackendConfigType): AppConfigType {
    return {
        local: localConfig,
        remote,
    };
}

export default localConfig;

let language: string = 'ru';
let deprecatedConfig: AppConfigType;
export function getLanguage(): string {
    return language;
}

export function setLanguage(lang: string): void {
    language = lang;
}

export function setConfig(cfg: AppConfigType): void {
    deprecatedConfig = cfg;
}

export function getConfig(): AppConfigType {
    return deprecatedConfig;
}
