import Need from "../Need";

export const NeedProjectsListView = new Need('projects.list.view');
export const NeedProjectsCreate = new Need('projects.create');
export const NeedProjectsEdit = new Need('projects.edit');
export const NeedProjectsView = new Need('projects.view');
export const NeedProjectsArchive = new Need('projects.archive');
export const NeedProjectsRestore = new Need('projects.restore');
export const NeedProjectsOrdersView = new Need('projects.orders.view');
export const NeedProjectsOrdersManage = new Need('projects.orders.manage');
export const NeedProjectsItemsView = new Need('projects.items.view');
export const NeedProjectsSync = new Need('projects.sync');
export const NeedProjectsManagersView = new Need('projects.managers.view');
export const NeedProjectsManagersManage = new Need('projects.managers.manage');
export const NeedProjectsContractorView = new Need('projects.contractor.view');
export const NeedProjectsContractorManage = new Need('projects.contractor.manage');
export const NeedProjectsAttachmentsView = new Need('projects.attachments.view');
export const NeedProjectsAttachmentsManage = new Need('projects.attachments.manage');
export const NeedProjectsCommentsView = new Need('projects.comments.view');
export const NeedProjectsCommentsManage = new Need('projects.comments.manage');
export const NeedProjectsCommentsAdd = new Need('projects.comments.add');
