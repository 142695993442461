import dayjs from 'dayjs';
import StructureNodeModel from 'modules/structure/models/StructureNodeModel';
import petrovich from 'petrovich';
import { t } from 'translations';
import CustomFieldModel from '../../../common/core/custom-fields/CustomFieldModel';
import { getConfig } from '../../../config/config';
import Model from '../../../utils/Model';
import ContractorModel from '../../contractors/models/ContractorModel';
import MailboxModel from './MailboxModel';

class UserModel extends Model {
    constructor(props) {
        let defAvatar = '/images/avatars/%GENDER%-default.svg';

        if (props === null) {
            props = {
                firstName: 'НЛО',
                isUfo: true,
            };
            defAvatar = '/images/avatars/ufo.svg';
        }
        super(props);

        this.defaultAvatar = defAvatar;

        if (props !== undefined) {
            this.structureNode = new StructureNodeModel(props.structureNode || {});
        }
    }

    initialize(props) {
        super.initialize(props);

        this.id = props.id || null;
        this.externalId = props.externalId || null;
        this.isActive = props.isActive !== undefined ? !!props.isActive : null;
        this.email = props.email || '';
        this.emailVerifiedAt = props.emailVerifiedAt ? dayjs(props.emailVerifiedAt) : null;
        this.phone = props.phone || '';
        this.firstName = props.firstName || '';
        this.middleName = props.middleName || '';
        this.lastName = props.lastName || '';
        this.rank = props.rank || '';
        this.gender = (props.gender && props.gender.value) || '';
        this.genderName = (props.gender && props.gender.name) || '';
        this.avatars = props.avatars || [];
        this.systemRole = (props.systemRole && props.systemRole.value) || '';
        this.systemRoleName = (props.systemRole && props.systemRole.name) || '';
        this.structureNodeId = props.structureNodeId || null;
        this.isUfo = props.isUfo || false;
        this.mailboxesCount = props.mailboxesCount || 0;
        this.featureMask = props.featureMask || 0;

        this.companyId = (props.company && props.company.id) || props.companyId || null;
        this.companyName = (props.company && props.company.name) || '';
        this.departmentId = (props.department && props.department.id) || props.departmentId || null;
        this.departmentName = (props.department && props.department.name) || '';

        this.structureNode = new StructureNodeModel(props.structureNode);

        this.contractor = new ContractorModel(props?.contractor ?? {});

        this.mailboxes =
            props.mailboxes && props.mailboxes.length
                ? props.mailboxes.map((item) => new MailboxModel(item))
                : [];

        this.profileUrl = this.id ? '/user/' + this.id : null;

        this.customFields = props.customFields || {};

        const fieldsData = props.fieldsMeta || {};

        this.fieldsMeta = [];
        for (let key in fieldsData) {
            if (fieldsData.hasOwnProperty(key)) {
                this.fieldsMeta.push(new CustomFieldModel(fieldsData[key]));
            }
        }
    }

    addMailbox(data) {
        this.mailboxes.push(new MailboxModel(data));
        this.mailboxesCount = this.mailboxes.length;
    }

    deleteMailbox(mailboxId) {
        this.mailboxes = this.mailboxes.filter((m) => m.id !== mailboxId);
        this.mailboxesCount = this.mailboxes.length;
    }

    editMailbox(mailboxId, data) {
        this.mailboxes = this.mailboxes.map((m) => {
            if (m.id !== mailboxId) return m;
            return new MailboxModel(data);
        });
    }

    getDefaultAvatar() {
        return this.defaultAvatar.replace('%GENDER%', this.gender);
    }

    getAvatar(thumb) {
        return this.avatars[thumb] || this.getDefaultAvatar();
    }

    person() {
        return {
            first: this.firstName,
            last: this.lastName,
            middle: this.middleName,
            gender: this.gender,
        };
    }

    genderize(male, female, ufo) {
        if (this.isUfo) return ufo;
        return this.gender === 'male' ? male : female;
    }
    /**
    genitives:

    nominative - именительный (кто? что?)
    genitive - родительный (кого? чего?)
    dative - дательный (кому? чему?)
    accusative - винительный (кого? что?)
    instrumental - творительный (кем? чем?)
    prepositional - предложный (о ком? о чем?)
   */
    getShortName(genitive = null) {
        const person =
            getConfig().remote.language === 'ru-RU' && genitive
                ? petrovich(this.person(), genitive)
                : this.person();
        let parts = [person.last, person.first[0] + '.'];
        if (person.middle) parts.push(person.middle[0] + '.');

        return parts.join(' ');
    }

    getFullName(genitive = null) {
        const person =
            getConfig().remote.language === 'ru-RU' && genitive
                ? petrovich(this.person(), genitive)
                : this.person();
        let parts = [person.last, person.first];
        if (person.middle) parts.push(person.middle);

        return parts.join(' ');
    }

    getStatusName() {
        switch (this.isActive) {
            case true:
                return t('users.statusActive', this.gender);
            case false:
                return t('users.statusInactive', this.gender);
            case null:
                return t('users.statusUnknown');
            default:
                return this.status;
        }
    }

    isFeatureEnabled(feature) {
        return this.featureMask & feature;
    }
}

export default UserModel;
