export default {
    'title': {
        ru: 'Отправка сообщения клиенту',
        en: 'Sending message to client',
    },
    'write': {
        ru: 'Написать клиенту',
        en: 'Write to client',
    },
    'noFromEmails': {
        ru: 'У Вас нет подключенных почтовых аккаунтов для отправки почты.',
        en: 'You have no available email accounts to send mails.',
    },
    'noToEmails': {
        ru: 'У контрагента нет контактных почтовых адресов.',
        en: 'Contractor has no contact mailing addresses.',
    },
    'from': {
        ru: 'От кого',
        en: 'From',
    },
    'to': {
        ru: 'Кому',
        en: 'To',
    },
    'subject': {
        ru: 'Тема',
        en: 'Subject',
    },
    'text': {
        ru: 'Текст',
        en: 'Text',
    },
    'attachments': {
        ru: 'Вложения',
        en: 'Attachments',
    },
    'templates': {
        ru: 'Шаблоны',
        en: 'Templates',
    },
    'send': {
        ru: 'Отправить',
        en: 'Send',
    },
    'sending': {
        ru: 'Отправка...',
        en: 'Sending...',
    },
    'sent': {
        ru: 'Писмо отправленно',
        en: 'Email successfully sent',
    },

}