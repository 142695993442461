import dayjs from 'dayjs';
import petrovich from 'petrovich';
import validators from 'utils/validators';
import { composeValidators } from 'utils/validators/compose';
import config from '../../../../config/config';
import Model from '../../../../utils/Model';

const enablePetrovich = config;

class ContractorPhysicalPassportModel extends Model {
    static validators = {
        firstName: composeValidators(validators.required),
        lastName: composeValidators(validators.required),
        middleName: composeValidators(validators.word),
        issuedAt: composeValidators(validators.date),
        birthDate: composeValidators(validators.date),
        series: composeValidators(validators.passport.series),
        number: composeValidators(validators.passport.number),
        phone: composeValidators(validators.required),
    };

    constructor(props, legal) {
        super(props);
        this.legal = legal;
    }

    initialize(props) {
        super.initialize(props);

        this.id = props.id || null;
        this.firstName = props.firstName || '';
        this.middleName = props.middleName || '';
        this.lastName = props.lastName || '';
        this.phone = props.phone || '';
        this.gender = props.gender.value || '';
        this.genderName = props.gender.name || '';
        this.series = props.series || '';
        this.number = props.number || '';
        this.issuedBy = props.issuedBy || '';
        this.issuedAt = props.issuedAt ? dayjs(props.issuedAt) : null;
        this.code = props.code || '';
        this.address = props.address || '';
        this.birthDate = props.birthDate ? dayjs(props.birthDate) : null;
    }

    getBirthDate() {
        return this.birthDate ? this.birthDate.format('DD.MM.YYYY') : '';
    }

    getPassportNumber() {
        return this.series + ' ' + this.number;
    }

    getPassportIssuer() {
        return (this.issuedAt ? this.issuedAt.format('DD.MM.YYYY') : '') + ' ' + this.issuedBy;
    }

    person() {
        return {
            first: this.firstName,
            last: this.lastName,
            middle: this.middleName,
            gender: this.gender,
        };
    }

    genderize(male, female) {
        return this.gender === 'male' ? male : female;
    }

    /**
   genitives:

   nominative - именительный (кто? что?)
   genitive - родительный (кого? чего?)
   dative - дательный (кому? чему?)
   accusative - винительный (кого? что?)
   instrumental - творительный (кем? чем?)
   prepositional - предложный (о ком? о чем?)
   */
    getShortName(genitive = null) {
        const person = genitive ? petrovich(this.person(), genitive) : this.person();
        let parts = [person.last, person.first[0] + '.'];
        if (person.middle) parts.push(person.middle[0] + '.');

        return parts.join(' ');
    }

    getFullName(genitive = null) {
        const person = genitive ? petrovich(this.person(), genitive) : this.person();
        let parts = [person.last, person.first];
        if (person.middle) parts.push(person.middle);

        return parts.join(' ');
    }
}

export default ContractorPhysicalPassportModel;
