export default {
    'title': {
        ru: 'Синхронизация',
        en: 'Synchronization',
    },
    'synchronization': {
        ru: 'Синхронизация',
        en: 'Synchronization',
    },
    'externalId': {
        ru: 'Внешний ID',
        en: 'External ID',
    },
    'success': {
        ru: 'Контрагент успешно синхронизирован',
        en: 'Client synchronized',
    },
    'synchronize': {
        ru: 'Синхронизировать сейчас',
        en: 'Synchronize now',
    },
    'synchronizing': {
        ru: 'Синхронизация...',
        en: 'Synchronizing...',
    },
}