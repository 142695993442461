//import libs
import ErrorBoundary from 'common/ui/misc/ErrorBoundary';
import PageLoading from 'common/ui/misc/PageLoading';
import React from 'react';
import Header from './blocks/header';

const DealerCabinetLayout = ({ children, user }) => {
    return (
        <>
            <Header
                showNotifications={false}
                showHamburger={false}
                showHomeButton={true}
                showLogo={true}
                style={{ marginLeft: '0px', width: '100%' }}
            />
            <div className="pcoded-main-container" style={{ marginLeft: '0px' }}>
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <ErrorBoundary>{user ? children : <PageLoading />}</ErrorBoundary>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DealerCabinetLayout;
