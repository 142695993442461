//import libs
import React from 'react'
import PropTypes from 'prop-types'
import {BackTop} from "antd";

// import components

function PublicLayout({children}) {
  return <div>
    <BackTop />
    <main>
      {children}
    </main>
  </div>
}

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PublicLayout
