//import libs
import ErrorBoundary from 'common/ui/misc/ErrorBoundary';
import PageLoading from 'common/ui/misc/PageLoading';
import PropTypes from 'prop-types';
import WebPushSubscriber from '../modules/notifications/web-push/components/WebPushSubscriber';
import WebSocketSubscriber from '../modules/notifications/web-socket/components/WebSocketSubscriber';
import useConfig from '../utils/hooks/useConfig';
import Header from './blocks/header';
import NavBar from './blocks/navbar';

const displayName = 'Private Layout';
const propTypes = {
    children: PropTypes.node.isRequired,
};

const PrivateLayout = ({ children, user }) => {
    const config = useConfig();
    return (
        <>
            <NavBar config={config} />
            <Header />
            <div className="pcoded-main-container">
                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                            <WebPushSubscriber />
                            <WebSocketSubscriber />
                            <ErrorBoundary>{user ? children : <PageLoading />}</ErrorBoundary>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

PrivateLayout.displayName = displayName;
PrivateLayout.propTypes = propTypes;

export default PrivateLayout;
