import OrderModel from 'modules/orders/models/OrderModel';
import Model from 'utils/Model';
import { composeValidators } from '../../../../utils/validators/compose';
import validators from '../../../../utils/validators';

class OrderItemModel extends Model {
    static validators = {
        code: composeValidators(
            validators.required,
            validators.maxLength(255),
            validators.minLength(3)
        ),
        count: composeValidators(validators.required, validators.number, validators.minNumber(0)),
        type: composeValidators(validators.required),
        unit: composeValidators(validators.required),
        cost: composeValidators(validators.required, validators.number, validators.minNumber(0)),
        price: composeValidators(validators.required, validators.number, validators.minNumber(0)),
    };

    initialize(props) {
        super.initialize(props);
        this.position = props.position || null;
        this.designId = props.designId || null;
        this.orderId = props.orderId || null;
        this.code = props.code || null;
        this.userCode = props.userCode || null;
        this.cost = parseInt(props.cost) || 0;
        this.costFormatted = props.costFormatted || null;
        this.price = parseInt(props.price) || 0;
        this.basePrice = parseInt(props.basePrice) || 0;
        this.priceFormatted = props.priceFormatted || null;
        this.basePriceFormatted = props.basePriceFormatted || null;
        this.count = props.count || null;
        this.baseCount = props.baseCount || null;
        this.isPriceModified = props.isPriceModified || false;
        this.isCostConfirmed = props.isCostConfirmed || false;
        this.isCountModified = props.isCountModified || false;
        this.isItem = props.isItem || false;
        this.isPart = props.isPart || false;
        this.isCustom = props.isCustom || false;

        this.type = (props.type && props.type.code) || '';
        this.typeName = (props.type && props.type.name) || '';

        this.unit = (props.unit && props.unit.code) || '';
        this.unitName = (props.unit && props.unit.name) || '';
        this.unitShortName = (props.unit && props.unit.short) || '';

        this.configuration = props.configuration || {};
        this.extraData = props.extraData || {};
        this.extraDataFormatted = props.extraDataFormatted || {};

        this.order = props.order ? new OrderModel(props.order) : new OrderModel();
    }
}

export default OrderItemModel;
