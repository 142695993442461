import Model from 'utils/Model'
import {composeValidators} from "../../../../utils/validators/compose";
import validators from "../../../../utils/validators";



class AttachmentModel extends Model {

  static validators = {
    raw_file: composeValidators(validators.required)
  };

  initialize(props) {
    super.initialize(props);
    this.projectId = props.projectId;
    this.size = props.size || 0;
    this.name = props.name || null;
    this.extension = props.extension || null;
    this.type = props.type || null;
    this.url = props.url || null;
    this.previewUrl = props.previewUrl || null;
  }

  getDate() {
    return this.createdAt ? this.createdAt.format('DD MMM YYYY HH:mm') : '';
  }

  getSize() {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (this.size == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(this.size) / Math.log(1024)));
    return Math.round(this.size / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };
}

export default AttachmentModel
