export default {
    general: {
        ru: 'Основное',
        en: 'General',
    },
    children: {
        ru: 'Узлы',
        en: 'Nodes',
    },
    managers: {
        ru: 'Пользователи',
        en: 'Users',
    },
    regions: {
        ru: 'Регионы',
        en: 'Regions',
    },
};
