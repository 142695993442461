export default {
    'title': {
        ru: 'Вложенные заказы',
        en: 'Nested orders',
    },
    'empty': {
        ru: 'Нет вложенных заказов',
        en: 'No nested orders',
    },
    'createNew': {
        ru: 'Создать новый заказ',
        en: 'Create new order',
    },
    'suborderFor': {
        ru: 'Подзаказ для',
        en: 'Suborder for',
    }
}
