export default {
    statuses: {
        currentStatus: {
            en: 'Current status',
            ru: 'Текущий статус',
        },
        changeConfirmation: {
            en: 'Change status?',
            ru: 'Сменить статус?',
        },
        emptyList: {
            en: 'No available statuses',
            ru: 'Нет доступных статусов',
        },
    },
    goToTask: {
        en: 'Go to task',
        ru: 'Перейти к задаче',
    },
    blocks: {
        info: {
            en: 'Info',
            ru: 'Информация',
        },
    },
    searchTask: {
        en: 'Search task...',
        ru: 'Поиск задачи...',
    },
    searchHint: {
        en: 'Summary, number, responsible',
        ru: 'Заголовок, номер, отвественные',
    },
    displayList: {
        en: 'List',
        ru: 'Список',
    },
    displayCalendar: {
        en: 'Calendar',
        ru: 'Календарь',
    },
    createdAt: {
        en: 'Created',
        ru: 'Создана',
    },
    desiredDate: {
        en: 'Desired date',
        ru: 'Желаемая дата',
    },
    plannedDate: {
        en: 'Planned date',
        ru: 'Назначенная дата',
    },
    number: {
        en: 'Number',
        ru: 'Номер',
    },
    summary: {
        en: 'Summary',
        ru: 'Заголовок',
    },
    status: {
        en: 'Status',
        ru: 'Статус',
    },
    responsible: {
        en: 'Responsible',
        ru: 'Ответсвенный',
    },
    tasks: {
        ru: 'Задачи',
        en: 'Tasks',
    },
    type: {
        en: 'Type',
        ru: 'Тип',
    },
    description: {
        en: 'Description',
        ru: 'Описание',
    },
    creation: {
        en: 'Task creation',
        ru: 'Создание задачи',
    },
    responsibleNotSet: {
        en: 'Not set',
        ru: 'Не назначен',
    },
    taskCreated: {
        en: 'Task created',
        ru: 'Задача создана',
    },
    taskWasCreated: {
        en: 'New task was created',
        ru: 'Новая задача успешно создана',
    },
    order: {
        en: 'Order',
        ru: 'Заказ',
    },
    orderNotLinked: {
        en: 'Order is not linked',
        ru: 'Заказ не привязан',
    },
    desiredDateNotSet: {
        en: 'Desired data is not set',
        ru: 'Желаемая дата не указана',
    },
    create: {
        en: 'Create task',
        ru: 'Создать задачу',
    },
    group: {
        en: 'Group',
        ru: 'Группа',
    },
};
