export default {
    'title': {
        ru: 'Информация о лицензии',
        en: 'License information',
    },
    'general': {
        ru: 'Информация',
        en: 'General',
    },
    'license': {
        ru: 'Лицензия',
        en: 'License',
    },
    'hosting': {
        ru: 'Хостинг',
        en: 'Hosting',
    },
    'billing': {
        ru: 'Оплата',
        en: 'Billing',
    },
    'type': {
        ru: 'Тип',
        en: 'Type',
    },
    'status': {
        ru: 'Статус',
        en: 'Status',
    },
    'users': {
        ru: 'Пользователи',
        en: 'Users',
    },
    'start': {
        ru: 'Начало',
        en: 'Start',
    },
    'end': {
        ru: 'Окончание',
        en: 'End',
    },
    'value': {
        ru: 'Задолженность',
        en: 'Debt',
    },
    'paymentDate': {
        ru: 'Дата оплаты',
        en: 'Payment date',
    },
    'paymentStatus': {
        ru: 'Статус оплаты',
        en: 'Payment status',
    },
    statuses: {
        'active': {
            ru: 'Активна',
            en: 'Active',
        },
        'inactive': {
            ru: 'Неактивна',
            en: 'Inactive',
        },
    },
    paymentStatuses: {
        'none': {
            ru: 'Не оплачена',
            en: 'Unpaid',
        },
        'partial': {
            ru: 'Оплачена частично',
            en: 'Partial paid',
        },
        'paid': {
            ru: 'Оплачена полностью',
            en: 'Fully paid',
        },
    }
}