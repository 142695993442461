export default {
    emptyList: {
        ru: 'Задачи не найдены',
        en: 'Tasks not found',
    },
    addTask: {
        ru: 'Добавить задачу',
        en: 'Add task',
    },
    task: {
        ru: 'Задача',
        en: 'Task',
    },
    measurement: {
        ru: 'Замер',
        en: 'Measurement',
    },
    delivery: {
        ru: 'Доставка',
        en: 'Delivery',
    },
    installation: {
        ru: 'Установка',
        en: 'Installation',
    },
    creating: {
        ru: (type) => `Создание задачи: ${type}`,
        en: (type) => `Create task: ${type}`,
    },
    editing: {
        ru: (type) => `Редактирование задачи: ${type}`,
        en: (type) => `Edit task: ${type}`,
    },
    responsible: {
        ru: 'Ответственный',
        en: 'Responsible',
    },
    startAt: {
        ru: 'Начало',
        en: 'Start at',
    },
    duration: {
        ru: 'Продолжительность (минут)',
        en: 'Duration (minutes)',
    },
    comment: {
        ru: 'Комментарий',
        en: 'Comment',
    },
};
