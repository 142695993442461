import { useLocation, useNavigate, useParams } from 'react-router-dom';

const withRouter = (Component) => (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const history = {
        push: (url) => navigate(url),
    };
    const match = {
        params,
    };

    return (
        <Component
            {...props}
            location={location}
            navigate={navigate}
            params={params}
            history={history}
            match={match}
        />
    );
};

export default withRouter;
