import loadable from '@react-loadable/revised';
import LoadingComponent from 'common/loader';
import type { ApplicationRoute } from 'routes/routes';

const routes: ApplicationRoute[] = [
    {
        path: '/login',
        exact: true,
        component: loadable({
            loader: () => import('./pages/login/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/login/token/:token',
        exact: true,
        component: loadable({
            loader: () => import('./pages/login/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/remind',
        exact: true,
        component: loadable({
            loader: () => import('./pages/remind/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/remind/:token/:id',
        exact: true,
        component: loadable({
            loader: () => import('./pages/remind/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/auth/verify-email/:token/:id',
        exact: true,
        component: loadable({
            loader: () => import('./pages/verify-email/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
];

export default routes;
