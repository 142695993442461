import React from 'react';
import {Navigate, Route, Outlet, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {protect} from "../common/core/access-control";

const PrivateRoute = ({isAuthenticated, guard}) => {
    const location = useLocation();
    if(!isAuthenticated) {
        sessionStorage.setItem('afterLoginRedirect', location.pathname);
        return <Navigate to={`/login?from=${location.pathname}`}/>
    }

    return <Outlet />;
}

function mapStateToProps(store) {
    return {
        isAuthenticated: store.auth.isAuthenticated,
    }
}

export default connect(mapStateToProps)(PrivateRoute)
