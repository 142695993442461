import React from 'react';
import {t} from 'translations';
import PropTypes from 'prop-types';

const styles = {
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        minHeight: '100vh',
        overflow: 'hidden'
    },
    icon: {
        fontSize: '40px',
        color: 'red',
        marginBottom: '25px'
    },
    error: {
        backgroundColor: 'white',
        textAlign: 'center',
        padding: '30px',
        borderRadius: '5px',
        minWidth: '300px'
    },
    text: {
        fontSize: '20px'
    }
}

const GlobalError = ({}) => {
    return (
        <div style={styles.container}>
            <div style={styles.error}>
                <i className="fa fa-times-circle-o" style={styles.icon} />
                <p style={styles.text}>
                    {t('common.errors.initializationError')}
                </p>
            </div>
        </div>
    );
};

GlobalError.propTypes = {};

export default GlobalError;
