import { isDayjs } from 'dayjs';
import { t } from '../../translations';
import bank from './bank';
import passport from './passport';

export default {
    required: (value) =>
        value !== undefined && value !== '' && value !== null
            ? undefined
            : t('validators.required'),
    requiredArray: (value) =>
        value !== undefined && value.length > 0 && value !== null
            ? undefined
            : t('validators.required'),
    word: (value) =>
        !value || /^[а-яёa-z]+$/i.test(value) ? undefined : 'Разрешены символы а-я, -',
    words: (value) =>
        !value || /^[а-яёa-z\-\s]+$/i.test(value) ? undefined : 'Разрешены символы а-я, - и пробел',
    date: (value) =>
        !value || isDayjs(value) || /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/i.test(value)
            ? undefined
            : 'Неверный формат (дд.мм.гггг)',
    number: (value) => (value && isNaN(Number(value)) ? t('validators.number') : undefined),
    id: (value) => (value && isNaN(Number(value)) && value > 0 ? 'Выберите из списка' : undefined),
    integer: (value) => (!value || /^[\d]+$/i.test(value) ? undefined : t('validators.integer')),
    minLength: (min) => (value) =>
        value && value.length < min ? t('validators.minLength', min) : undefined,
    maxLength: (max) => (value) =>
        value && value.length > max ? t('validators.maxLength', max) : undefined,
    minNumber: (min) => (value) =>
        value && (value = parseFloat(value)) && value < min
            ? t('validators.minNumber', min)
            : undefined,
    maxNumber: (max) => (value) =>
        value && (value = parseFloat(value)) && value > max
            ? t('validators.maxNumber', max)
            : undefined,
    bank,
    passport,
};
