export default {
    'passwordChanging': {
        ru: 'Изменение пароля',
        en: 'Password changing',
    },
    'currentPassword': {
        ru: 'Текущий пароль',
        en: 'Current password',
    },
    'newPassword': {
        ru: 'Новый пароль',
        en: 'New password',
    },
    'confirmNewPassword': {
        ru: 'Подтвердите новый пароль',
        en: 'Confirm new password',
    },
    'changePassword': {
        ru: 'Изменить пароль',
        en: 'Change password',
    },
    'passwordChanged': {
        ru: 'Пароль изменён успешно',
        en: 'Password successfully changed',
    },
}