import Model from 'utils/Model';
import validators from 'utils/validators';
import { composeValidators } from 'utils/validators/compose';

class ContractorEntityPassportModel extends Model {
    static validators = {
        name: composeValidators(validators.required),
        inn: composeValidators(validators.bank.inn.organisation, validators.required),
        kpp: composeValidators(validators.bank.kpp),
        ogrn: composeValidators(validators.bank.ogrn),
        okpo: composeValidators(validators.bank.okpo.organisation),
    };

    constructor(props, legal) {
        super(props);
        this.legal = legal;
    }

    initialize(props) {
        super.initialize(props);
        // if(this.legal !== 'physical')
        //   throw new Error('Wrong contractor legal.');

        this.id = props.id || null;
        this.name = props.name || '';
        this.inn = props.inn || '';
        this.kpp = props.kpp || '';
        this.ogrn = props.ogrn || '';
        this.okpo = props.okpo || '';
        this.okato = props.okato || '';

        this.address = props.address || '';
    }
}

export default ContractorEntityPassportModel;
