export default {
    'today': {
        ru: 'Сегодня',
        en: 'Today',
    },
    'week': {
        ru: 'Неделя',
        en: 'Week',
    },
    'month': {
        ru: 'Месяц',
        en: 'Month',
    },
    'day': {
        ru: 'День',
        en: 'Day',
    },
    'list': {
        ru: 'Список',
        en: 'List',
    },
}