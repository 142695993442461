import Http from '../../utils/Http';

class EventsServiceClass {
    fetchCount = () => {
        return new Promise((resolve, reject) => {
            Http.get('/events/count')
                .then((resp) => resolve(resp.data))
                .catch((err) => {
                    console.log(err);
                    return reject(err);
                });
        });
    };
    fetchEvents = (from) => {
        return new Promise((resolve, reject) => {
            Http.get(`/events?from=${from}`)
                .then((resp) => resolve(resp.data))
                .catch((err) => {
                    console.log(err);
                    return reject(err);
                });
        });
    };
}

export const EventsService = new EventsServiceClass();
