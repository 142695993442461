import Http from '../../../utils/Http';
import { WebSocketConnectionTokenResponse, WebSocketSubscriptionTokensResponse } from './types';

export default class WebSocketApi {
    static getConnectionToken: () => Promise<WebSocketConnectionTokenResponse> = async () => {
        const r = await Http.get<WebSocketConnectionTokenResponse>('/ws/connection');
        return r.data;
    };
    static getSubscriptionTokens: () => Promise<WebSocketSubscriptionTokensResponse> = async () => {
        const r = await Http.get<WebSocketSubscriptionTokensResponse>('/ws/subscriptions');
        return r.data;
    };
}
