import Need from "../Need";

export const NeedContractorsListView = new Need('contractors.list.view');
export const NeedContractorsListViewOwn = new Need('contractors.list.view.own');
export const NeedContractorsCreate = new Need('contractors.create');
export const NeedContractorsEdit = new Need('contractors.edit');
export const NeedContractorsEditOwn = new Need('contractors.edit.own');
export const NeedContractorsView = new Need('contractors.view');
export const NeedContractorsViewOwn = new Need('contractors.view.own');
export const NeedContractorsArchive = new Need('contractors.archive');
export const NeedContractorsArchiveOwn = new Need('contractors.archive.own');
export const NeedContractorsRestore = new Need('contractors.restore');
export const NeedContractorsRestoreOwn = new Need('contractors.restore.own');
export const NeedContractorsOrdersView = new Need('contractors.orders.view');
export const NeedContractorsOrdersViewOwn = new Need('contractors.orders.view.own');
export const NeedContractorsPaymentsView = new Need('contractors.payments.view');
export const NeedContractorsPaymentsViewOwn = new Need('contractors.payments.view.own');
export const NeedContractorsPaymentsManage = new Need('contractors.payments.manage');
export const NeedContractorsPaymentsManageOwn = new Need('contractors.payments.manage.own');
export const NeedContractorsEmailsView = new Need('contractors.emails.view');
export const NeedContractorsEmailsViewOwn = new Need('contractors.emails.view.own');
export const NeedContractorsManagersView = new Need('contractors.managers.view');
export const NeedContractorsManagersViewOwn = new Need('contractors.managers.view.own');
export const NeedContractorsManagersManage = new Need('contractors.managers.manage');
export const NeedContractorsManagersManageOwn = new Need('contractors.managers.manage.own');
export const NeedContractorsContactsView = new Need('contractors.contacts.view');
export const NeedContractorsContactsViewOwn = new Need('contractors.contacts.view.own');
export const NeedContractorsContactsManage = new Need('contractors.contacts.manage');
export const NeedContractorsContactsManageOwn = new Need('contractors.contacts.manage.own');
export const NeedContractorsAccountsView = new Need('contractors.accounts.view');
export const NeedContractorsAccountsViewOwn = new Need('contractors.accounts.view.own');
export const NeedContractorsAccountsManage = new Need('contractors.accounts.manage');
export const NeedContractorsAccountsManageOwn = new Need('contractors.accounts.manage.own');
export const NeedContractorsSync = new Need('contractors.sync');
export const NeedContractorsSyncOwn = new Need('contractors.sync.own');
export const NeedContractorsCommentsView = new Need('contractors.comments.view');
export const NeedContractorsCommentsManage = new Need('contractors.comments.manage');
export const NeedContractorsCommentsManageOwn = new Need('contractors.comments.manage.own');
export const NeedContractorsCommentsAdd = new Need('contractors.comments.add');
