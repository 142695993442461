import CustomFieldModel from 'common/core/custom-fields/CustomFieldModel';
import StructureNodeTypeModel from 'modules/structure/models/StructureNodeTypeModel';
import UserModel from 'modules/users/models/UserModel';
import Model from '../../../utils/Model';

class StructureNodeModel extends Model {
    static validators = {};

    initialize(props) {
        super.initialize(props);
        this.name = props.name || '';
        this.code = props.code || '';
        this.type = new StructureNodeTypeModel(props.type);

        this.parent = props.parent ? new StructureNodeModel(props.parent) : null;

        this.setChildren(props.children || []);
        this.setManagers(props.users || []);

        this.managersCount = this.managersCount || props.managersCount || 0;
        this.childrenCount = this.childrenCount || props.childrenCount || 0;

        this.customFields = props.customFields || {};

        const fieldsData = props.fieldsMeta || {};

        this.fieldsMeta = [];
        for (let key in fieldsData) {
            if (fieldsData.hasOwnProperty(key)) {
                this.fieldsMeta.push(new CustomFieldModel(fieldsData[key]));
            }
        }
    }

    setChildren(children) {
        this.children = [];
        this.childrenCount = 0;
        if (Array.isArray(children)) {
            this.children = children.map((c) =>
                c instanceof StructureNodeModel ? c : new StructureNodeModel(c)
            );
        }
        this.childrenCount = this.children.length;
    }

    setManagers(managers) {
        this.managers = [];
        this.managersCount = 0;
        if (Array.isArray(managers)) {
            this.managers = managers.map((m) => (m instanceof UserModel ? m : new UserModel(m)));
        }
        this.managersCount = this.managers.length;
    }
}

export default StructureNodeModel;
