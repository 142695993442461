/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import * as a from './action-types';


export function navbarCollapse() {
  return {
    type: a.NAVBAR_COLLAPSE,
    collapsed: true,
  }
}

export function navbarExpand() {
  return {
    type: a.NAVBAR_EXPAND,
    collapsed: false
  }
}

export function navbarMobileOpen() {
  return {
    type: a.NAVBAR_MOBILE_OPEN,
    mobileOpened: true
  }
}

export function navbarMobileClose() {
  return {
    type: a.NAVBAR_MOBILE_CLOSE,
    mobileOpened: false
  }
}
