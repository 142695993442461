import { getLanguage } from '../config/config';
import Utils from '../utils';
import messages from './messages';

export const t = (path: string, ...args: any[]): string => {
    let message = Utils.valueAtPath(messages, [...path.split('.'), getLanguage()]); //TODO: use combined config
    if (message !== undefined && typeof message === 'function') {
        message = message(...args);
    }

    if (message === undefined) {
        //console.error('Path not found: ' + path);
    }
    return message || path;
};
