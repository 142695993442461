export default {
    'addAttachment': {
        ru: 'Добавить вложение',
        en: 'Add attachment',
    },
    'attachments': {
        ru: 'Вложения',
        en: 'Attachments',
    },
    'attachmentsEmptyList': {
        ru: 'Вложения еще не добавлены',
        en: 'Attachments are not attached yet',
    },
    'attachmentCreation': {
        ru: 'Загрузка вложения',
        en: 'Attachment upload',
    },
    'attachmentEdition': {
        ru: 'Редактирование вложения',
        en: 'Attachment edition',
    },
    'attachmentDeleteConfirmation': {
        ru: 'Вы уверены, что хотите удалить вложение?',
        en: 'Are you sure want to delete attachment?',
    },
    'attachmentName': {
        ru: 'Название',
        en: 'Name',
    },
    'attachmentNameHint': {
        ru: 'Оставьте пустым для использования имени файла',
        en: 'Leave it blank for use original file name',
    },
    'attachmentFile': {
        ru: 'Файл',
        en: 'File',
    },
    'attachmentFileHint': {
        ru: 'Максимум 5 Мб',
        en: '5 MB maximum',
    },
    'name': {
        ru: 'Название',
        en: 'Name',
    },
    'size': {
        ru: 'Размер',
        en: 'Size',
    },
    'added': {
        ru: 'Добавлен',
        en: 'Added',
    },
}