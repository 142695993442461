import Forbidden from 'modules/web/pages/forbidden';
import type { FC, ReactElement } from 'react';
import { Guard } from './Guard';
import Requirement from './Requirement';

export default function protect(requirement: Requirement | Requirement[], fallback?: ReactElement) {
    if (fallback === undefined) {
        fallback = <Forbidden />;
    }

    return function (ComponentToProtect: FC) {
        return (props: any) => {
            return (
                <Guard requirement={requirement} fallback={fallback}>
                    <ComponentToProtect {...props}>{props.children}</ComponentToProtect>
                </Guard>
            );
        };
    };
}
