export default {
    commentsTitle: {
        ru: 'Комментарии',
        en: 'Comments',
    },
    addComment: {
        ru: 'Написать',
        en: 'Add comment',
    },
    commentsListIsEmpty: {
        ru: 'Комментариев пока нет',
        en: 'Comments list is empty yet',
    },
    wrote: {
        ru: (gender) => {
            switch (gender) {
                case 'male':
                    return 'написал';
                case 'female':
                    return 'написала';
                default:
                    return 'написало';
            }
        },
        en: 'wrote',
    },
    enterText: {
        ru: 'Введите текст комментария',
        en: 'Enter comment text...',
    },
    deleteConfirmation: {
        ru: 'Вы уверены, что хотите удалить комментарий?',
        en: 'Are you sure want to delete comment?',
    },
    reply: {
        ru: (gender) => {
            switch (gender) {
                case 'male':
                    return 'ответил';
                case 'female':
                    return 'ответила';
                default:
                    return 'ответило';
            }
        },
        en: 'reply',
    },
    show: {
        en: 'Show',
        ru: 'Показать',
    },
};
