// import libs
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useConfig from '../../../../../utils/hooks/useConfig';
import MenuCollapse from './MenuCollapse';

//import components

const displayName = 'Mobile brand';

const propTypes = {
    onCollapseClick: PropTypes.func,
};

function Mobile({ onCollapseClick, collapsed, showHamburger = true }) {
    const config = useConfig();
    return (
        <div className="m-header">
            {showHamburger && <MenuCollapse onClick={onCollapseClick} collapsed={collapsed} />}
            <Link to="/" className="b-brand">
                <img
                    className="b-img"
                    src={`/logo/${config.remote.tenantConfig.logo.main}`}
                    alt="EasyOrder CRM"
                />
            </Link>
        </div>
    );
}

Mobile.displayName = displayName;
Mobile.propTypes = propTypes;

export default Mobile;
