import HTTP from '../../../utils/Http';
import {
  AUTH_CHECK,
  AUTH_LOGIN,
  AUTH_LOGOUT, AUTH_PERMISSIONS,
  AUTH_REFRESH_TOKEN, AUTH_USER,
} from './action-types';
import {localLogout} from "../service";

const initialState = {
  isAuthenticated: false,
  permissions: [],
  user: null
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case AUTH_REFRESH_TOKEN:
    case AUTH_LOGIN:
      return login(state, payload);
    case AUTH_CHECK:
      return checkAuth(state);
    case AUTH_LOGOUT:
      return logout(state);
    case AUTH_USER:
      return authUser(state, payload);
    case AUTH_PERMISSIONS:
      return authPermissions(state, payload);
    default:
      return state;
  }
};

function login(state, payload) {
  localStorage.setItem('access_token', payload.accessToken);
  localStorage.setItem('refresh_token', payload.refreshToken);

  HTTP.defaults.headers.common.Authorization = `Bearer ${payload.accessToken}`;

  return {
    ...state, isAuthenticated: true,
  };
}

function authUser(state, payload) {

  return {
    ...state,
    user: payload
  };
}
function authPermissions(state, payload) {

  return {
    ...state,
    permissions: payload || []
  };
}

function checkAuth(state) {
  state = Object.assign({}, state, {
    isAuthenticated: !!localStorage.getItem('access_token'),
  });

  if (state.isAuthenticated) {
    HTTP.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
  }

  return state;
}

function logout(state)  {

  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');

  HTTP.defaults.headers.common.Authorization = null;
  return {
    ...state, isAuthenticated: false, user: null
  };
}

export const getAuth = state => state.auth.isAuthenticated;

export default reducer;
