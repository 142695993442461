import Http from '../../../utils/Http';

class WebPushService {
    static hasPermission() {
        return Notification.permission === 'granted';
    }

    static isDefaultPermission() {
        return Notification.permission === 'default';
    }

    static async requestPermission() {
        return await Notification.requestPermission();
    }

    static async getSubscription() {
        return await navigator.serviceWorker.ready.then(async (registration) => {
            return await registration.pushManager.getSubscription();
        });
    }

    static async subscribe(vapidPublicKey) {
        const registration = await navigator.serviceWorker.ready;
        return await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: vapidPublicKey,
        });
    }

    static async unsubscribe() {
        const subscription = await this.getSubscription();
        if (subscription) {
            await subscription.unsubscribe();
        }
        return subscription;
    }

    static async storeSubscription(subscription) {
        return new Promise((resolve, reject) => {
            Http.post('/web-push/subscribe', { subscription })
                .then((resp) => resolve(resp.data))
                .catch((err) => {
                    console.log(err);
                    return reject(err);
                });
        });
    }

    static async removeSubscription(subscription) {
        return new Promise((resolve, reject) => {
            Http.post('/web-push/unsubscribe', { subscription })
                .then((resp) => resolve(resp.data))
                .catch((err) => {
                    console.log(err);
                    return reject(err);
                });
        });
    }
}

export default WebPushService;
