export default {
    'general': {
        ru: 'Информация',
        en: 'Information',
    },
    'orders': {
        ru: 'Заказы',
        en: 'Orders',
    },
    'payments': {
        ru: 'Платежи',
        en: 'Payments',
    },
    'emails': {
        ru: 'Переписка',
        en: 'Messaging',
    },
}