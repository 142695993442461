export default {
    'itemsTitle': {
        ru: 'Товары',
        en: 'Items',
    },
    'emptyItemsList': {
        ru: 'Список товаров пуст',
        en: 'There are no items',
    },
    'servicesTitle': {
        ru: 'Услуги',
        en: 'Services',
    },
    'emptyServicesList': {
        ru: 'Список услуг пуст',
        en: 'There are no services',
    },
    'emptyPartsList': {
        ru: 'Список деталей пуст',
        en: 'There are no parts',
    },
    'type': {
        ru: 'Тип',
        en: 'Type',
    },
    'name': {
        ru: 'Название',
        en: 'Name',
    },
    'unit': {
        ru: 'Единица',
        en: 'Unit',
    },
    'code': {
        ru: 'Код',
        en: 'Code',
    },
    'count': {
        ru: 'Количество',
        en: 'Count',
    },
    'cost': {
        ru: 'Стоимость',
        en: 'Cost',
    },
    'price': {
        ru: 'Цена',
        en: 'Price',
    },
    'addItemToOrder': {
        ru: 'Добавить предмет в заказ',
        en: 'Add item to order',
    },
    'itemDeleteConfirmation': {
        ru: 'Вы уверены, что хотите удалить предмет из заказа?',
        en: 'Are you sure you want to delete item from order?',
    },
    'addedByUser': {
        ru: 'Добавлено пользователем',
        en: 'Added by user',
    },
    'changedByUser': {
        ru: v => `Изменено пользователем\r\nБазовое значение: ${v}`,
        en: v => `Changed by user\r\nBase value: ${v}`,
    },
    'fromConfiguration': {
        ru: 'Получено из конфигурации',
        en: 'Received from Design',
    },
    'costApproved': {
        ru: 'Стоимость подтверждена',
        en: 'Cost approved',
    },
    'itemEdition': {
        ru: 'Редактирование товара',
        en: 'Edit item',
    },
    'serviceEdition': {
        ru: 'Редактирование услуги',
        en: 'Edit service',
    },
    'addition': {
        ru: 'Добавление предмета в заказ',
        en: 'Add item',
    },
    'total': {
        ru: 'Итого',
        en: 'Total',
    },
    'basePrice': {
        ru: 'Базовая цена',
        en: 'Base price',
    },
    'baseCount': {
        ru: 'Базовое количество',
        en: 'Base count',
    },
}