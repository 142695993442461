// import libs
import { Guard } from 'common/core/access-control';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SlideDown } from 'react-slidedown';
import _ from 'lodash';

// initialize component
import Item from './Item';

class Group extends Component {
    // set name of the component
    static displayName = 'Navigation group';

    // validate props
    static propTypes = {
        item: PropTypes.object.isRequired,
        level: PropTypes.number.isRequired,
    };

    static defaultProps = {
        level: 0,
    };

    childStatuses = [];

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            active: false,
            activeItems: {},
        };

        this.toggleExpand = this.toggleExpand.bind(this);
        this.handleActiveChanged = this.handleActiveChanged.bind(this);
    }

    toggleExpand() {
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    handleActiveChanged(id, isActive) {
        let aItems = this.state.activeItems;
        aItems[id] = isActive;

        const active = _.findKey(aItems, (i) => i === true) !== undefined;

        this.setState({
            activeItems: aItems,
            expanded: active,
            active,
        });
    }

    // render component
    render() {
        const { level } = this.props;
        const group = this.props.item;
        const { active, expanded } = this.state;
        const cls = classNames(
            'nav-item pcoded-hasmenu',
            { active: active },
            { 'pcoded-trigger': expanded }
        );
        const submenuCls = classNames('pcoded-submenu', { expanded: true });
        return (
            <li className={cls}>
                <a className="nav-link cr-pointer" onClick={this.toggleExpand}>
                    <span className="pcoded-micon">
                        <i className={group.icon || 'feather icon-chevrons-right'} />
                    </span>
                    <span className="pcoded-mtext">{group.caption}</span>
                </a>
                <SlideDown closed={!expanded}>
                    <ul className={submenuCls}>
                        {group.items.map((item, i) => {
                            this.childStatuses[i] = false;
                            const el = (
                                <Item
                                    key={i}
                                    id={i}
                                    item={item}
                                    level={level + 1}
                                    onActiveChanged={this.handleActiveChanged}
                                />
                            );
                            if (item.guard !== undefined) {
                                return (
                                    <Guard requirement={item.guard} key={i}>
                                        {el}
                                    </Guard>
                                );
                            }
                            return el;
                        })}
                    </ul>
                </SlideDown>
            </li>
        );
    }
}

export default Group;
