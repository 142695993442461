import Model from 'utils/Model';
import validators from 'utils/validators';
import { composeValidators } from 'utils/validators/compose';

class CommunicationModel extends Model {
    static validators = {
        value: composeValidators(validators.required),
        type: composeValidators(validators.required),
    };

    initialize(props) {
        super.initialize(props);

        this.id = props.id;
        this.contactId = props.contactId;
        this.isPrimary = props.isPrimary !== undefined ? !!props.isPrimary : null;
        this.type = props.type || '';
        this.typeName = props.typeName || '';
        this.value = props.value || '';
    }
}

export default CommunicationModel;
