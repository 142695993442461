import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const propTypes = {
  caption: PropTypes.string,
};

function Error({caption}) {
  const cls = classNames(
    'feather icon-x-circle'
  );
  return <span className="text-danger">
    <span className={cls}/>
      {caption && " " + caption}
  </span>;
}

Error.propTypes = propTypes;

export default Error;