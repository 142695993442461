export default {
    'createOrderInStatus': {
        ru: gender => {
            switch(gender) {
                case 'male': return 'создал заказ в статусе';
                case 'female': return 'создала заказ в статусе';
                default: return 'создало заказ в статусе';
            }
        },
        en: 'create order in status',
    },
    'editStatusFrom': {
        ru: gender => {
            switch(gender) {
                case 'male': return 'изменил статус с';
                case 'female': return 'изменила статус с';
                default: return 'изменило статус с';
            }
        },
        en: 'changed status from',
    },
    'to': {
        ru: 'на',
        en: 'to',
    },
}