// import libs
import { connect } from 'react-redux';

// import components
import NavBar from './NavBar';

const mapStateToProps = (state) => ({
    collapsed: state.layout.navBar.collapsed,
    mobileOpened: state.layout.navBar.mobileOpened,
});

export default connect(mapStateToProps, null, null)(NavBar);
