import * as a from './action-types';

const initialState = {
  collapsed: false,
  mobileOpened: false
};

const reducer = (state = initialState, { type }) => {
  switch (type) {
    case a.NAVBAR_COLLAPSE:
      return collapse(state);
    case a.NAVBAR_EXPAND:
      return expand(state);
    case a.NAVBAR_MOBILE_OPEN:
      return mobileOpen(state);
    case a.NAVBAR_MOBILE_CLOSE:
      return mobileClose(state);
    default:
      return state;
  }
};

function collapse(state) {
  return {
    ...state,
    collapsed: true,
    mobileOpened: false,
  };
}

function expand(state) {
  return {
    ...state,
    collapsed: false,
    mobileOpened: false,
  };
}

function mobileOpen(state) {
  return {
    ...state,
    mobileOpened: true,
    collapsed: false,
  };
}

function mobileClose(state) {
  return {
    ...state,
    mobileOpened: false,
    collapsed: false,
  };
}


export default reducer;
