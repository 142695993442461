import actions from './actions';
import auth from './auth';
import calendar from './calendar';
import catalogs from './catalogs';
import comments from './comments';
import common from './common';
import contractors from './contractors';
import dealer from './dealer';
import events from './events';
import license from './license';
import navigation from './navigation';
import notifications from './notifications';
import orders from './orders';
import projects from './projects';
import regions from './regions';
import stats from './stats';
import structure from './structure';
import tasks from './tasks';
import users from './users';
import validators from './validators';

export default {
    common,
    auth,
    actions,
    navigation,
    validators,
    users,
    comments,
    contractors,
    projects,
    orders,
    structure,
    tasks,
    calendar,
    license,
    stats,
    catalogs,
    events,
    dealer,
    regions,
    notifications,
};
