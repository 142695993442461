/**
 * Main store function
 */
import { applyMiddleware, compose, createStore } from 'redux';
import { thunk } from 'redux-thunk';
// import { createLogger } from 'redux-logger'
import rootReducer from './reducers';

export default function (initialState = {}) {
    // Middleware and store enhancers
    const enhancers = [applyMiddleware(thunk)];

    if (process.env.NODE_ENV !== 'production') {
        // enhancers.push(applyMiddleware(createLogger()))
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
            enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__()); // eslint-disable-line
    }

    const store = createStore(rootReducer, initialState, compose(...enhancers));

    // For hot reloading reducers
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            const nextReducer = require('./reducers').default; // eslint-disable-line global-require
            store.replaceReducer(nextReducer);
        });
    }

    return store;
}
