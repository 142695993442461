export default {
    'general': {
        ru: 'Основное',
        en: 'General',
    },
    'items': {
        ru: 'Состав',
        en: 'Items',
    },
    'parts': {
        ru: 'Детали',
        en: 'Parts',
    },
    'documents': {
        ru: 'Документы',
        en: 'Documents',
    },
    'payments': {
        ru: 'Платежи',
        en: 'Payments',
    },
    'claims': {
        ru: 'Претензии',
        en: 'Claims',
    },
    'tasks': {
        ru: 'Задачи',
        en: 'Tasks',
    },
    'history': {
        ru: 'История',
        en: 'History',
    },
}