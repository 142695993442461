import Need from "../Need";

export const NeedUsersListView = new Need('users.list.view');
export const NeedUsersCreate = new Need('users.create');
export const NeedUsersEdit = new Need('users.edit');
export const NeedUsersEditOwn = new Need('users.edit.own');
export const NeedUsersView = new Need('users.view');
export const NeedUsersViewOwn = new Need('users.view.own');
export const NeedUsersDelete = new Need('users.delete');
export const NeedUsersStatusChange = new Need('users.status.change');
export const NeedUsersRoleEdit = new Need('users.role.edit');
export const NeedUsersMailboxesManage = new Need('users.mailboxes.manage');
export const NeedUsersMailboxesManageOwn = new Need('users.mailboxes.manage.own');
export const NeedUsersMailboxesView = new Need('users.mailboxes.view');
export const NeedUsersMailboxesViewOwn = new Need('users.mailboxes.view.own');
