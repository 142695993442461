import policies from './policies';

export default {
    titleIndex: {
        ru: 'Управление каталогами',
        en: 'Catalog management',
    },
    titleMy: {
        ru: 'Мои каталоги',
        en: 'My catalogs',
    },
    titleCreation: {
        ru: 'Добавление каталога',
        en: 'New catalog',
    },
    titleEdit: {
        ru: (name) => 'Редактирование каталога ' + name,
        en: (name) => 'Edit catalog ' + name,
    },
    titleView: {
        ru: (name) => 'Каталог ' + name,
        en: (name) => 'Catalog ' + name,
    },
    name: {
        ru: 'Название',
        en: 'Name',
    },
    path: {
        ru: 'Путь',
        en: 'Path',
    },
    pathHint: {
        ru: (path) => 'Относительный путь %ProgramData%/2020/Design/12/' + path,
        en: (path) => 'Relative path %ProgramData%/2020/Design/12/' + path,
    },
    purgeHint: {
        ru: 'Очистить целевую папку (для каталогов только папка с каталогом будет очищена)',
        en: 'Clear target folder (only catalog folder will be cleared for catalogs)',
    },
    purge: {
        ru: 'Очищать перед установкой',
        en: 'Purge before install',
    },
    policy: {
        ru: 'Политика обновления',
        en: 'Update policy',
    },
    is_active: {
        ru: 'Активен',
        en: 'Active',
    },
    version: {
        ru: 'Версия',
        en: 'Version',
    },
    versions: {
        ru: 'Версии',
        en: 'Versions',
    },
    general: {
        ru: 'Основная информация',
        en: 'General information',
    },
    selectFileToUpload: {
        ru: 'Загрузить новую версию',
        en: 'Upload a new version',
    },
    upload: {
        ru: 'Загрузить',
        en: 'Upload',
    },
    processing: {
        ru: 'Обработка',
        en: 'Processing',
    },
    uploading: {
        ru: (a, b) => `Загружено ${a} из ${b}`,
        en: (a, b) => `Uploaded ${a} of ${b}`,
    },
    versionCreated: {
        ru: 'Новая версия успешно создана',
        en: 'New version successfully created',
    },
    versionNumber: {
        ru: 'Версия',
        en: 'Version',
    },
    versionSize: {
        ru: 'Размер',
        en: 'Size',
    },
    versionDate: {
        ru: 'Дата',
        en: 'Date',
    },
    versionInstallations: {
        ru: 'Всего установок',
        en: 'Total installations',
    },
    versionInstalled: {
        ru: 'Установлено сейчас',
        en: 'Currently installed',
    },
    installedVersion: {
        ru: 'Установлена',
        en: 'Installed',
    },
    notInstalled: {
        ru: 'Каталог не установлен',
        en: 'Catalog not installed',
    },
    desiredVersion: {
        ru: 'Желаемая',
        en: 'Desired',
    },
    desiredLatest: {
        ru: 'Последняя',
        en: 'Latest',
    },
    desiredNone: {
        ru: 'Нет',
        en: 'None',
    },
    desiredNoInstall: {
        ru: 'Не устанавливать',
        en: 'Disable installation',
    },
    setAsDesired: {
        ru: 'Установить как желаемую версию',
        en: 'Set as desired version',
    },
    resetDesired: {
        ru: 'Сбросить желаемую версию',
        en: 'Reset desired version',
    },
    settingVersion: {
        ru: 'Установка желаемой версии',
        en: 'Configuring desired version',
    },
    versionWouldBeInstalled: {
        ru: 'Желаемая версия изменена',
        en: 'Desired version changed',
    },
    checkUpdatesNow: {
        ru: 'Проверить обновления каталогов',
        en: 'Check updates now',
    },
    actualVersion: {
        ru: 'Актуальная версия',
        en: 'Actual version',
    },
    confirmDelete: {
        ru: 'Вы уверены, что хотите удалить каталог?',
        en: 'Are you sure want to delete catalog?',
    },
    confirmDeleteVersion: {
        ru: (version) => `Вы уверены, что хотите удалить версию ${version}?`,
        en: (version) => `Are you sure want to delete version ${version}?`,
    },
    departments: {
        ru: 'Доступен подразделениям',
        en: 'Available for departments',
    },
    policies,
};
