import PageLoading from 'common/ui/misc/PageLoading';
import type { ComponentType } from 'react';

interface LoadingComponentProps {
    retry: () => void;
    error?: Error;
}

const LoadingComponent: ComponentType<LoadingComponentProps> = ({ error }) => {
    if (error) {
        throw error;
    } else {
        return <PageLoading />;
    }
};

export default LoadingComponent;
