import sections from './sections';

export default {
    sections,
    emptyList: {
        ru: 'Дочерние узлы отсутствуют',
        en: 'Empty list',
    },
    title: {
        ru: 'Структура',
        en: 'Structure',
    },
    node: {
        ru: 'Узел',
        en: 'Node',
    },
    createTitle: {
        ru: 'Добавление узла',
        en: 'Create node',
    },
    editTitle: {
        ru: (name) => `Редактирование ${name}`,
        en: (name) => `Edit ${name}`,
    },
    searchPlaceholder: {
        ru: 'Поиск узла',
        en: 'Search node',
    },
    parentNode: {
        ru: 'Родительский узел',
        en: 'Parent node',
    },
    code: {
        ru: 'Код',
        en: 'Code',
    },
    name: {
        ru: 'Название',
        en: 'Name',
    },
    type: {
        ru: 'Тип',
        en: 'Type',
    },
    edit: {
        ru: 'Редактировать узел',
        en: 'Edit node',
    },
    managersCount: {
        ru: 'Пользователи',
        en: 'Users',
    },
    generalInformation: {
        ru: 'Основная информация',
        en: 'General',
    },
    addChild: {
        ru: 'Добавить дочерний узел',
        en: 'Add child node',
    },
    attachManager: {
        ru: 'Привязать менеджера',
        en: 'Attach manager',
    },
    emptyManagersList: {
        ru: 'Менеджеры не привязаны',
        en: 'No managers attached',
    },
    availableRegions: {
        ru: 'Все регионы',
        en: 'All regions',
    },
    selectedRegions: {
        ru: 'Выбранные регионы',
        en: 'Selected regions',
    },
};
