/* ============
 * Model
 * ============
 *
 * The base model.
 *
 * Model are used to map the data
 * and help in avoiding code repetition
 * For instance,
 * if we need to get user full name by joining first and last name
 * or if we want to manipulate user dates
 * we can write a function
 */
import dayjs, { isDayjs } from 'dayjs';
import _ from 'lodash';

class Model {
    initialized = false;

    constructor(props) {
        if (props === undefined || props === null) props = {};
        this.initialize(props);
    }

    initialize(props) {
        this.id = (props.id && Number(props.id)) || null;
        this.createdAt = (props.createdAt && dayjs(props.createdAt)) || null;
        this.updatedAt = (props.updatedAt && dayjs(props.updatedAt)) || null;
        this.deletedAt = (props.deletedAt && dayjs(props.deletedAt)) || null;
        this.regions = props.regions ?? [];
        this.initialized = true;
    }

    toJson(fields) {
        const props = Object.assign({}, this);

        _.forOwn(props, (value, key) => {
            if (fields && !fields.hasOwnProperty(key)) return;

            if (isDayjs(value)) {
                props[key] = value.format('YYYY-MM-DD HH:mm:ss');
            }

            if (value instanceof Model) {
                props[key] = value.toJson();
            }
        });
        return props;
    }

    deepFind(path) {
        let paths = path.split('.'),
            current = this,
            i;
        for (i = 0; i < paths.length; ++i) {
            const pathSegment = _.camelCase(paths[i]);
            if (current[pathSegment] === undefined) {
                return undefined;
            } else {
                current = current[pathSegment];
            }
        }
        return current;
    }
}

export default Model;
