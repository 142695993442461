// auth action types
export const NAVBAR_COLLAPSE = 'NAVBAR_COLLAPSE';
export const NAVBAR_EXPAND = 'NAVBAR_EXPAND';
export const NAVBAR_MOBILE_CLOSE = 'NAVBAR_MOBILE_CLOSE';
export const NAVBAR_MOBILE_OPEN = 'NAVBAR_MOBILE_OPEN';

export default {
  NAVBAR_COLLAPSE,
  NAVBAR_EXPAND,
  NAVBAR_MOBILE_CLOSE,
  NAVBAR_MOBILE_OPEN
};