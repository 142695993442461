export default {
    'emptyList': {
        ru: 'Платежи по данному заказу ещё не поступали',
        en: 'There are no payments yet',
    },
    'create': {
        ru: 'Добавить платёж',
        en: 'Add payment',
    },
    'edit': {
        ru: 'Редактировать',
        en: 'Edit',
    },
    'delete': {
        ru: 'Удалить',
        en: 'Delete',
    },
    'creating': {
        ru: 'Добавление платежа',
        en: 'Create payment',
    },
    'editing': {
        ru: 'Редактирование платежа',
        en: 'Edit payment',
    },
    'date': {
        ru: 'Дата',
        en: 'Date',
    },
    'value': {
        ru: 'Сумма',
        en: 'Amount',
    },
    'number': {
        ru: 'Номер',
        en: 'Number',
    },
    'paymentDeleteConfirmation': {
        ru: 'Вы уверены, что хотите удалить платёж?',
        en: 'Are you sure want to delete payment?',
    },
}