export default {
    auto: {
        ru: 'Автоматически',
        en: 'Automatic',
    },
    latest: {
        ru: 'Всегда последняя версия',
        en: 'Latest version always',
    },
    manual: {
        ru: 'Выбор версии вручную',
        en: 'Manual version select',
    },
};
