import Utils from 'utils';
import claims from './claims';
import configuration from './configuration';
import contractor from './contractor';
import documents from './documents';
import history from './history';
import items from './items';
import managers from './managers';
import nested from './nested';
import payments from './payments';
import project from './project';
import realisations from './realisations';
import sections from './sections';
import statuses from './statuses';
import synchronization from './synchronization';
import tasks from './tasks';

export default {
    sections,
    managers,
    synchronization,
    statuses,
    configuration,
    contractor,
    items,
    project,
    documents,
    payments,
    claims,
    tasks,
    history,
    realisations,
    nested,
    orders: {
        ru: 'Заказы',
        en: 'Orders',
    },
    order: {
        ru: 'Заказ',
        en: 'Order',
    },
    orderCard: {
        ru: 'Карточка заказа',
        en: 'Order card',
    },
    searchPlaceholder: {
        ru: 'Поиск заказа...',
        en: 'Order search...',
    },
    searchHint: {
        ru: 'Поиск по номеру заказа, адресу, инн и названию контрагента, фио менеджера',
        en: 'Search order by number, manager, contractor',
    },
    type: {
        ru: 'Тип',
        en: 'Type',
    },
    number: {
        ru: 'Номер',
        en: 'Number',
    },
    createdAt: {
        ru: 'Создан',
        en: 'Created',
    },
    contractorField: {
        ru: 'Контрагент',
        en: 'Contractor',
    },
    status: {
        ru: 'Статус',
        en: 'Status',
    },
    region: {
        ru: 'Регион',
        en: 'Region',
    },
    managersField: {
        ru: 'Менеджеры',
        en: 'Managers',
    },
    structureNode: {
        ru: 'Подразделение',
        en: 'Department',
    },
    company: {
        ru: 'Компания',
        en: 'Company',
    },
    department: {
        ru: 'Подразделение',
        en: 'Department',
    },
    warehouse: {
        ru: 'Склад',
        en: 'Warehouse',
    },
    comment: {
        ru: 'Комментарий к заказу',
        en: 'Comment',
    },
    finances: {
        ru: 'Финансы',
        en: 'Finances',
    },
    general: {
        ru: 'Основная информация',
        en: 'General information',
    },
    totalValue: {
        ru: 'Сумма',
        en: 'Total value',
    },
    totalValueWithoutDiscount: {
        ru: 'Сумма без скидки',
        en: 'Total value without discount',
    },
    discount: {
        ru: 'Скидка',
        en: 'Discount',
    },
    paidValue: {
        ru: 'Оплачено',
        en: 'Paid value',
    },
    paidAt: {
        ru: 'Дата оплаты',
        en: 'Paid at',
    },
    withoutNumber: {
        ru: 'Б/Н',
        en: 'N/A',
    },
    locked: {
        ru: 'Конфигурация заморожена',
        en: 'Order configuration is locked',
    },
    num: {
        ru: (count) => Utils.plural(['заказ', 'заказа', 'заказов'], count),
        en: 'orders',
    },
    regionDefaultsWasApplied: {
        ru: 'Значения регона применены',
        en: 'Defaults from region were applied',
    },
    selectedRegionUnavailable: {
        ru: 'Выбранный регион недоступен и был изменён',
        en: 'Selected region unavailable and was changed',
    },
};
