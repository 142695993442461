// import libs
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import PageHeader from "common/ui/page-header/PageHeader";
import {t} from '../../../../translations'

// initialize component
class Page extends Component {
  // set name of the component
  static displayName = 'ForbiddenPage'

  // validate props
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  // after mounting the component add a style to the body
  componentDidMount() {

  }

  // remove body style before component leaves dom
  componentWillUnmount() {

  }

  // render component
  render() {

    return (
      <div className="error-page">
        <PageHeader title={this.title} breadcrumbs={this.breadcrumbs}/>
        <div className="main-body">
          <div className="page-wrapper">
            <div className="card">
              <div className="card-header dark">
                <h5 className="text-danger">{t('common.forbidden')}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Page
