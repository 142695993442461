// import libs
import NotificationCounter from 'modules/events/components/NotificationCounter';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UserModel from 'modules/users/models/UserModel';
import { Link } from 'react-router-dom';
import { t } from '../../../../../translations';

// import components

// initialize component
class UserMenu extends Component {
    // set name of the component
    static displayName = 'User menu';

    // validate props
    static propTypes = {
        user: PropTypes.instanceOf(UserModel),
        onLogout: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            rendered: false,
        };
    }

    toggleMenu = () => {
        this.setState({
            opened: !this.state.opened,
            rendered: true,
        });
    };
    close = () => {
        this.setState({ opened: false });
    };
    // render component
    render() {
        const { user, onLogout } = this.props;
        if (user === null) return '';

        const { opened, rendered } = this.state;

        const avatarUrl = user.getAvatar('small');

        const menuCls = classNames(
            'dropdown-menu dropdown-menu-right profile-notification animated',
            { bounceIn: opened },
            { bounceOut: !opened }
        );
        const btnClass = classNames('dropdown drp-user', { show: opened });
        return (
            <li>
                <div className={btnClass}>
                    <a className="dropdown-toggle cr-pointer" onClick={this.toggleMenu}>
                        <i className="icon feather icon-settings" />
                    </a>
                    {rendered && (
                        <div className={menuCls} style={{ right: 0 }}>
                            <div className="pro-head">
                                <img
                                    src={avatarUrl}
                                    className="img-radius"
                                    alt="User-Profile-Image"
                                />
                                <span>{user.getShortName()}</span>
                                <a
                                    className="dud-logout cr-pointer"
                                    title={t('actions.logout')}
                                    onClick={onLogout}
                                >
                                    <i className="feather icon-log-out" />
                                </a>
                            </div>
                            <ul className="pro-body">
                                <li>
                                    <Link
                                        to="/profile"
                                        className="dropdown-item"
                                        onClick={this.close}
                                    >
                                        <i className="feather icon-user" /> {t('common.profile')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </li>
        );
    }
}

export default UserMenu;
