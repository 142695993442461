export default {
    'emptyList': {
        ru: 'Претензии отсутсвуют',
        en: 'There are no claims yet',
    },
    'responsible': {
        ru: 'Ответственный',
        en: 'Responsible',
    },
    'name': {
        ru: 'Заголовок',
        en: 'Title',
    },
    'description': {
        ru: 'Описание',
        en: 'Description',
    },
    'creating': {
        ru: 'Добавление претензии',
        en: 'Claim create',
    },
    'editing': {
        ru: 'Редактирование претензии',
        en: 'Claim edit',
    },
    'create': {
        ru: 'Добавить претензию',
        en: 'Create claim',
    },
    'close': {
        ru: 'Закрыть претензию',
        en: 'Close claim',
    },
    'reopen': {
        ru: 'Открыть претензию',
        en: 'Reopen claim',
    },
    'opened': {
        ru: 'Открыта',
        en: 'Opened',
    },
    'closed': {
        ru: 'Закрыта',
        en: 'Closed',
    },
    'attachFile': {
        ru: 'Привязать файл',
        en: 'Attach file',
    },
    'noFiles': {
        ru: 'Файлы ещё не прикреплены',
        en: 'No attached files',
    },
}