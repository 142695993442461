import Model from '../../../utils/Model'


class CompanyModel extends Model {

  static validators = {
  };

  initialize(props) {
    super.initialize(props);
    this.id = props.id;
    this.name = props.name || '';
    this.code = props.code || '';

  }
}

export default CompanyModel
