import sections from './sections';
import emails from './emails';
import payments from './payments';
import orders from './orders';
import managers from './managers';
import contacts from './contacts';
import accounts from './accounts';
import synchronization from './synchronization';

export default {
    sections,
    emails,
    payments,
    orders,
    managers,
    contacts,
    accounts,
    synchronization,
    title: {
        ru: 'Контрагенты',
        en: 'Clients',
    },
    searchTitle: {
        ru: 'Название, отрасль, менеджер...',
        en: 'Name, field, manager...',
    },
    contractor: {
        ru: 'Контрагент',
        en: 'Client',
    },
    contractorCard: {
        ru: 'Карточка контрагента',
        en: 'Client card',
    },
    fields: {
        ru: 'Отрасли',
        en: 'Fields',
    },
    attachedManagers: {
        ru: 'Менеджеры',
        en: 'Managers',
    },
    contractorCreating: {
        ru: 'Создание контрагента',
        en: 'Client creating',
    },
    general: {
        ru: 'Основная информация',
        en: 'General information',
    },
    group: {
        ru: 'Группа',
        en: 'Group',
    },
    type: {
        ru: 'Тип',
        en: 'Type',
    },
    legal: {
        ru: 'Правовая форма',
        en: 'Legal',
    },
    name: {
        ru: 'Наименование',
        en: 'Name',
    },
    emptyName: {
        ru: 'Без названия',
        en: 'Empty name',
    }
};
