import {
  CONTRACTORS_DATA_LOADED,
} from './action-types';


const initialState = {
  data: null
};

const reducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case CONTRACTORS_DATA_LOADED:
      return storeData(state, payload);
    default:
      return state;
  }
};


function storeData(state, payload) {

  return {
    ...state,
    data: payload
  };
}

export default reducer;
