import Model from '../../../utils/Model'
import {t} from '../../../translations'

class StructureNodeTypeModel extends Model {

    static validators = {
    };

    initialize(props) {
        super.initialize(props);
        this.name = props.name || '';
        this.code = props.code || '';
    }
}

export default StructureNodeTypeModel
