export default {
    addAccount: {
        ru: 'Добавить почтовый аккаунт',
        en: 'Add new email account',
    },
    emptyAccountsList: {
        ru: 'Нет подключенных почтовых аккаунтов',
        en: "User haven't mail accounts yet",
    },
    accountLastCheck: {
        ru: 'Сбор почты',
        en: 'Collect emails',
    },
    accountFolders: {
        ru: 'Папки',
        en: 'Folders',
    },
    configureFolders: {
        ru: 'Настроить папки',
        en: 'Configure folders',
    },
    accountDeleteConfirmation: {
        ru: 'Вы уверены, что хотите отвязать почтовый аккаунт?',
        en: 'Are you sure want to delete mail account?',
    },
    incomingShort: {
        ru: 'Вх',
        en: 'In',
    },
    incomingUnavailable: {
        ru: 'Сбор входящей почты недоступен',
        en: 'Collecting incoming mail is unavailable',
    },
    incomingAvailable: {
        ru: 'Сбор входящей почты доступен',
        en: 'Collecting incoming mail is available',
    },
    outgoingShort: {
        ru: 'Исх',
        en: 'Out',
    },
    outgoingUnavailable: {
        ru: 'Отправка почты недоступен',
        en: 'Sending mail is unavailable',
    },
    outgoingAvailable: {
        ru: 'Отправка почты доступен',
        en: 'Sending mail is available',
    },
    creatingAccount: {
        ru: 'Добавление почтового аккаунта',
        en: 'Adding mail account',
    },
    editingAccount: {
        ru: (email) => `Редактирование почтового аккаунта ${email}`,
        en: (email) => `Editing mail account  ${email}`,
    },
    foldersControl: {
        ru: (email) => `Управление папками для ${email}`,
        en: (email) => `Folders configuring for ${email}`,
    },
    address: {
        ru: 'Адрес email',
        en: 'Email address',
    },
    login: {
        ru: 'Логин',
        en: 'Login',
    },
    password: {
        ru: 'Пароль',
        en: 'Password',
    },
    changeCredentials: {
        ru: 'Изменить данные авторизации',
        en: 'Change credentials',
    },
    incomingMail: {
        ru: 'Входящая почта',
        en: 'Incoming emails',
    },
    collectMail: {
        ru: 'Собирать почту с аккаунта',
        en: 'Collect emails',
    },
    outgoingMail: {
        ru: 'Исходящая почта',
        en: 'Outgoing emails',
    },
    sendMail: {
        ru: 'Отправлять почту с аккаунта',
        en: 'Send emails',
    },
    type: {
        ru: 'Тип',
        en: 'Type',
    },
    host: {
        ru: 'Хост',
        en: 'Host',
    },
    port: {
        ru: 'Порт',
        en: 'Port',
    },
    encryption: {
        ru: 'Шифрование',
        en: 'Encryption',
    },
    withoutEncryption: {
        ru: 'Без шифрования',
        en: 'Without encryption',
    },
};
