export default {
    'initializationError': {
        ru: 'Во время инициализации приложения произошла ошибка',
        en: 'Error occurred during initialization',
    },
    'oops': {
        ru: 'Упс...',
        en: 'Oops...',
    },
    'pageError': {
        ru: 'Во время загрузки страницы произошла ошибка.',
        en: 'Something went wrong during page loading.',
    },
}