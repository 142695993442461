import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldRender: this.props.shown,
            hiding: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.shown && !this.props.shown) {
            this.setState({ hiding: true });
            setTimeout(() => this.setState({ shouldRender: false, hiding: false }), 500);
        } else if (!prevProps.shown && this.props.shown) {
            this.setState({ shouldRender: true, hiding: false });
        }
    }

    render() {
        const { children, large, small } = this.props;

        const { shouldRender, hiding } = this.state;

        if (!shouldRender) {
            document.body.classList.remove('modal-open');
            return null;
        }
        document.body.classList.add('modal-open');

        const cls = classNames('modal-dialog', { 'modal-lg': large }, { 'modal-sm': small });

        return (
            <div className="dpws-modal">
                <div className={'modal-backdrop animated ' + (hiding ? 'fadeOut' : 'fadeIn')} />
                <div className="modal">
                    <div className={cls}>
                        <div
                            className={
                                'modal-content animated ' + (hiding ? 'slideOutUp' : 'slideInDown')
                            }
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Modal.Header = (props) => {
    return (
        <div className="modal-header">
            <h5 className="modal-title">{props.children}</h5>
            {props.onClose && (
                <button type="button" className="close" onClick={props.onClose}>
                    <span>×</span>
                </button>
            )}
        </div>
    );
};

Modal.Body = (props) => {
    return <div className="modal-body">{props.children}</div>;
};

Modal.Footer = (props) => {
    return <div className="modal-footer">{props.children}</div>;
};

Modal.propTypes = {
    shown: PropTypes.bool,
    small: PropTypes.bool,
    large: PropTypes.bool,
    onClose: PropTypes.func,
};

Modal.defaultProps = {};

export default Modal;
