export default class Requirement {
    constructor() {
        if (new.target === Requirement) {
            throw new TypeError('Cannot construct Requirement instances directly');
        }
    }

    isSatisfied = (credentials: string[]): boolean => {
        return false;
    };
}
