import React from 'react';
import PropTypes from 'prop-types';

import PageHeader from 'common/ui/page-header/PageHeader';
import Spinner from './Spinner';
import { t } from 'translations';

const propTypes = {
    caption: PropTypes.string,
    title: PropTypes.string,
};

function PageLoading({ caption = t('common.loading'), title = t('common.loadingPleaseWait') }) {
    return (
        <div>
            <PageHeader title={title} />
            <div className="main-body">
                <div className="page-wrapper">
                    <div className="card">
                        <div className="card-block p-2">
                            <div className="row">
                                <div className="col-sm-12">
                                    <Spinner caption={caption} small />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

PageLoading.propTypes = propTypes;

export default PageLoading;
