import React from 'react';
import PropTypes from 'prop-types';

const styles = {
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '100%',
        minHeight: '100vh',
        overflow: 'hidden'
    },
    loader: {
        color: 'white',
        fontSize: '40px'
    }
}

const GlobalLoader = ({}) => {
    return (
        <div style={styles.container}>
            <i style={styles.loader} className={`fa fa-spin fa-spinner`}/>
        </div>
    );
};

GlobalLoader.propTypes = {};

export default GlobalLoader;
