export default {
    'title': {
        ru: 'Счета',
        en: 'Bank accounts',
    },
    'creation': {
        ru: 'Добавление счёта',
        en: 'Bank account creation',
    },
    'edition': {
        ru: 'Редактирование счёта',
        en: 'Bank account edition',
    },
    'emptyList': {
        ru: 'Счетов нет',
        en: 'No bank accounts',
    },
    'deleteConfirmation': {
        ru: 'Вы уверены, что хотите удалить счёт?',
        en: 'are you sure want to delete bank account?',
    },
    'otherTitle': {
        ru: 'Прочее',
        en: 'Other',
    },
    'comment': {
        ru: 'Комментарий',
        en: 'Comment',
    },
}