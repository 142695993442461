/* eslint-disable no-console */
import { message } from 'antd';
import axios from 'axios';
import { localLogout, refreshToken } from 'modules/auth/service';
import store from 'store';
import { t } from 'translations';
import config from '../config/config';
import { setAxiosTimeout } from './debug';

let isRefreshing = false;
let refreshSubscribers = [];

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
setAxiosTimeout(config.apiTimeout);

axios.interceptors.request.use(
    function (config) {
        if (!config.url.startsWith('/')) {
            config.url = '/' + config.url;
        }

        const debug = localStorage.getItem('debug') || false;
        if (debug === 'on' || debug === '1' || debug === 'true' || debug === 'yes') {
            const url = new URL(config.baseURL + config.url);
            url.searchParams.append('XDEBUG_SESSION', 'PHPSTORM');
            config.url = url.toString();
        }

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        if (response.status === 301 || response.status === 302) throw Error('wrong response');
        isRefreshing = false;
        return response;
    },
    (error) => {
        if (error.response === undefined) {
            message.error(t('common.apiRequestError'));
            return Promise.reject(error);
        }
        const {
            config,
            response: { status },
        } = error;
        const originalRequest = config;
        if (isRefreshing) {
            store.dispatch(localLogout());
            return Promise.reject(error);
        }

        if (status === 401) {
            localStorage.removeItem('access_token');
            if (!isRefreshing) {
                isRefreshing = true;
                store
                    .dispatch(refreshToken(localStorage.getItem('refresh_token')))
                    .then((newToken) => {
                        isRefreshing = false;
                        onRefreshed(newToken);
                    });
            }

            return new Promise((resolve, reject) => {
                subscribeTokenRefresh((token) => {
                    if (token) {
                        // replace expired token and retry
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        resolve(axios(originalRequest));
                    }
                });
            });
        } else {
            let msg = 'Http transport error';
            if (error.response && error.response.data) {
                msg = error.response.data.message;
            }
            if (msg) message.error(msg);
        }
        return Promise.reject(error);
    }
);

export function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}

export function onRefreshed(token) {
    refreshSubscribers.map((cb) => cb(token));
    refreshSubscribers = [];
}

export default axios;
