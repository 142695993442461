import Need from "../Need";

export const NeedTasksView = new Need('tasks.view');
export const NeedTasksManage = new Need('tasks.manage');
export const NeedTasksCreate = new Need('tasks.create');
export const NeedTasksPlan = new Need('tasks.plan');
export const NeedTasksGroupsAll = new Need('tasks.groups.all');
export const NeedTasksCommentsView = new Need('tasks.comments.view');
export const NeedTasksCommentsManage = new Need('tasks.comments.manage');
export const NeedTasksCommentsAdd = new Need('tasks.comments.add');
