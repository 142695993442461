export default {
    'title': {
        ru: 'Заказы',
        en: 'Orders',
    },
    'attach': {
        ru: 'Привязать',
        en: 'Attach',
    },
    'emptyList': {
        ru: 'Заказы не прикреплены',
        en: 'Orders not attached',
    },
    'orderDetachConfirmation': {
        ru: 'Вы уверены, что хотите отвязать заказ?',
        en: 'Are you sure want to detach order?',
    },
    'number': {
        ru: 'Номер',
        en: 'Number',
    },
    'status': {
        ru: 'Статус',
        en: 'Status',
    },
    'contractor': {
        ru: 'Контрагент',
        en: 'Contractor',
    },
    'value': {
        ru: 'Сумма',
        en: 'Total',
    },
    'attaching': {
        ru: 'Привязка заказа',
        en: 'Attach order',
    },
    'search': {
        ru: 'Поиск заказа',
        en: 'Search order',
    },
    '': {
        ru: '',
        en: '',
    },
}