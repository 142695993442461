export default {
    'signIn': {
        ru: 'Авторизация',
        en: 'Sign in'
    },
    'signInWait': {
        ru: 'Авторизация...',
        en: 'Please, wait...'
    },
    'logIn': {
        ru: 'Войти',
        en: 'Log In'
    },
    'email': {
        ru: 'EMail',
        en: 'EMail'
    },
    'password': {
        ru: 'Пароль',
        en: 'Password'
    },
    'confirmPassword': {
        ru: 'Повторите пароль',
        en: 'Confirm password'
    },
    'forgot': {
        ru: 'Забыли пароль?',
        en: 'Forgot password?'
    },
    'restore': {
        ru: 'Восстановить',
        en: 'Restore it'
    },
    'finishRegistration': {
        ru: 'Завершить регистрацию',
        en: 'Finish registration'
    },
    'registrationSuccessful': {
        ru: 'Регистрация успешно завершена!',
        en: 'Registration completed!'
    },
    'choosePassword': {
        ru: 'Придумайте пароль',
        en: 'Choose new password'
    },
    'passwordsMustMatch': {
        ru: 'Пароли должны совпадать',
        en: 'Passwords must match'
    },
    'remindPassword': {
        ru: 'Восстановление пароля',
        en: 'Restore password',
    },
    'verifyingToken': {
        ru: 'Проверка токена...',
        en: 'Token verification...',
    },
    'emailConfirmed': {
        ru: 'Email успешно подтверждён!',
        en: 'Email successfully confirmed!',
    },
    'invalidToken': {
        ru: 'Неверный токен',
        en: 'Invalid token',
    },
    'remindEmailSent': {
        ru: 'Вам отправлено письмо с инструкциями',
        en: 'Email with further instructions has been sent',
    },
    'changePassword': {
        ru: 'Установить новый пароль',
        en: 'Set new password',
    },
    'successReset': {
        ru: 'Пароль успешно изменён',
        en: 'Password changed successfully',
    },
}