import Model from 'utils/Model'
import {composeValidators} from "utils/validators/compose";
import validators from "utils/validators";
import UserModel from "../../../users/models/UserModel";



class FieldModel extends Model {

  initialize(props) {
    super.initialize(props);
    this.contractorId = props.contractorId || null;
    this.fieldId = props.fieldId || null;
    this.name = props.name || null;
  }


}

export default FieldModel
