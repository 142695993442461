import loadable from '@react-loadable/revised';
import LoadingComponent from 'common/loader';
import type { ApplicationRoute } from 'routes/routes';

const routes: ApplicationRoute[] = [
    {
        path: '/exchange/:spec?',
        exact: true,
        auth: true,
        component: loadable({
            loader: () => import('./pages/spec/index.js').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
];

export default routes;
