import mailboxes from './mailboxes';
import sections from './sections';
import security from './security';

export default {
    sections,
    mailboxes,
    security,
    users: {
        ru: 'Пользователи',
        en: 'Users',
    },
    userCreating: {
        ru: 'Создание пользователя',
        en: 'User creating',
    },
    searchPlaceholder: {
        ru: 'ФИО, email',
        en: 'Name, email',
    },
    statusActive: {
        ru: (gender) => (gender === 'male' ? 'Активен' : 'Активна'),
        en: 'Active',
    },
    statusInactive: {
        ru: (gender) => (gender === 'male' ? 'Неактивен' : 'Неактивна'),
        en: 'Inactive',
    },
    statusUnknown: {
        ru: 'Неизвестно',
        en: 'Unknown',
    },
    userProfile: {
        ru: (name) => (name ? `Профиль ${name}` : 'Профиль пользователя'),
        en: (name) => (name ? `Profile of ${name}` : 'User profile'),
    },
    deleteConfirm: {
        ru: 'Вы уверены, что хотите удалить пользователя?',
        en: 'Are you sure want to delete user?',
    },
    changeStatusConfirm: {
        ru: (isActive) =>
            `Вы уверены, что хотите ${isActive ? 'отключить' : 'активировать'} пользователя?`,
        en: (isActive) => `Are you sure want to ${isActive ? 'deactivate' : 'activate'} user?`,
    },
    emailNotConfirmed: {
        ru: 'EMail не подтверждён',
        en: 'EMail not confirmed',
    },
    avatarChangeTitle: {
        ru: 'Смена фотографии',
        en: 'Change avatar',
    },
    changeAvatar: {
        ru: 'Изменить фотографию',
        en: 'Change avatar',
    },
    avatarChooseAnotherFile: {
        ru: 'Выбрать другой файл',
        en: 'Choose another file',
    },
    changeEmail: {
        ru: 'Изменить email',
        en: 'Change email',
    },
    emailChanging: {
        ru: 'Смена email',
        en: 'Changing email',
    },
    changeEmailWarningTitle: {
        ru: 'Обратите внимание!',
        en: 'Warning!',
    },
    changeEmailWarning: {
        ru: 'Изменение Email адреса пользователя сделает невозможной его авторизацию до подтверждения нового адреса!',
        en: "User can't sign in until he confirm new email!",
    },
    emailInformationText: {
        ru: 'На указанный EMail будет отправленно письмо с дальнейшими инструкциями по активации учётной записи сразу после сохранения.',
        en: 'Information text',
    },
    newEmail: {
        ru: 'Новый email',
        en: 'New email',
    },
    generalInformation: {
        ru: 'Основная информация',
        en: 'General information',
    },
    rank: {
        ru: 'Должность',
        en: 'Rank',
    },
    name: {
        ru: 'Имя',
        en: 'Name',
    },
    firstName: {
        ru: 'Имя',
        en: 'Name',
    },
    middleName: {
        ru: 'Отчество',
        en: 'Patronymic',
    },
    lastName: {
        ru: 'Фамилия',
        en: 'Surname',
    },
    gender: {
        ru: 'Пол',
        en: 'Gender',
    },
    phone: {
        ru: 'Телефон',
        en: 'Phone',
    },
    systemSettings: {
        ru: 'Системные настройки',
        en: 'System settings',
    },
    role: {
        ru: 'Роль',
        en: 'Role',
    },
    externalId: {
        ru: 'Внешний ID',
        en: 'External ID',
    },
    nodeNotSet: {
        ru: 'Не привязан',
        en: 'Not set',
    },
    node: {
        ru: 'Узел',
        en: 'Node',
    },
    department: {
        ru: 'Подразделение',
        en: 'Department',
    },
    company: {
        ru: 'Компания',
        en: 'Company',
    },
    contractor: {
        ru: 'Контрагент дилера',
        en: 'Dealer contractor',
    },
    contractorHint: {
        ru: 'Начните вводить имя контрагента',
        en: "Start typing contractor's name",
    },
    withoutDepartment: {
        ru: 'Без подразделения',
        en: 'Without department',
    },
    failedToLoad: {
        ru: 'Не удалось загрузать пользователя',
        en: 'Failed to load user',
    },
    structureSettings: {
        ru: 'Структура',
        en: 'Structure',
    },
    structureNode: {
        ru: 'Подразделение',
        en: 'Department',
    },
};
