import Model from 'utils/Model'
import {composeValidators} from "utils/validators/compose";
import validators from "utils/validators";
import CustomFieldModel from "../../../../common/core/custom-fields/CustomFieldModel";



class AccountModel extends Model {

  static validators = {
    bik: composeValidators(validators.bank.bik, validators.required),
    corrNumber: composeValidators(validators.bank.accountNumber, validators.required),
    number: composeValidators(validators.bank.accountNumber, validators.required),
    bank: composeValidators(validators.required),
  };

  initialize(props) {
    super.initialize(props);
    this.id = props.id;
    this.isPrimary = props.isPrimary !== undefined ? !!props.isPrimary : null;
    this.bank = props.bank || '';
    this.bik = props.bik || '';
    this.corrNumber = props.corrNumber || '';
    this.number = props.number || '';
    this.comment = props.comment || '';

    this.customFields = props.customFields || {};

    const fieldsData = props.fieldsMeta || {};

    this.fieldsMeta = [];
    for (let key in fieldsData) {
      if (fieldsData.hasOwnProperty(key)) {
        this.fieldsMeta.push(new CustomFieldModel(fieldsData[key]));
      }
    }
  }


}

export default AccountModel
