import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useConfig from '../../../../../utils/hooks/useConfig';
import MenuCollapse from './MenuCollapse';

const propTypes = {
    onCollapseClick: PropTypes.func,
};

function Desktop(props) {
    const config = useConfig();

    return (
        <div className="navbar-brand header-logo">
            <Link to="/" className="b-brand">
                <img
                    className="b-img"
                    src={`/logo/${config.remote.tenantConfig.logo.main}`}
                    alt="EasyOrder CRM"
                />
            </Link>
            <MenuCollapse onClick={props.onCollapseClick} collapsed={props.collapsed} />
        </div>
    );
}

Desktop.propTypes = propTypes;

export default Desktop;
