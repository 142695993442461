import Need from "../Need";

export const NeedOrdersListView = new Need('orders.list.view');
export const NeedOrdersListViewOwn = new Need('orders.list.view.own');
export const NeedOrdersCreate = new Need('orders.create');
export const NeedOrdersViewOwn = new Need('orders.view.own');
export const NeedOrdersViewNode = new Need('orders.view.node');
export const NeedOrdersViewChild = new Need('orders.view.child');
export const NeedOrdersEdit = new Need('orders.edit');
export const NeedOrdersEditOwn = new Need('orders.edit.own');
export const NeedOrdersView = new Need('orders.view');
export const NeedOrdersFilterAll = new Need('orders.filter.all');
export const NeedOrdersFilterWithOwnTask = new Need('orders.filter.with.own.task');
export const NeedOrdersFinancesView = new Need('orders.finances.view');
export const NeedOrdersArchive = new Need('orders.archive');
export const NeedOrdersArchiveOwn = new Need('orders.archive.own');
export const NeedOrdersRestore = new Need('orders.restore');
export const NeedOrdersRestoreOwn = new Need('orders.restore.own');
export const NeedOrdersConfigurationManage = new Need('orders.configuration.manage');
export const NeedOrdersConfigurationManageOwn = new Need('orders.configuration.manage.own');
export const NeedOrdersStatusManage = new Need('orders.status.manage');
export const NeedOrdersStatusManageOwn = new Need('orders.status.manage.own');
export const NeedOrdersManagersView = new Need('orders.managers.view');
export const NeedOrdersManagersViewOwn = new Need('orders.managers.view.own');
export const NeedOrdersManagersManage = new Need('orders.managers.manage');
export const NeedOrdersManagersManageOwn = new Need('orders.managers.manage.own');
export const NeedOrdersContractorView = new Need('orders.contractor.view');
export const NeedOrdersContractorViewOwn = new Need('orders.contractor.view.own');
export const NeedOrdersContractorSendEmail = new Need('orders.contractor.send.email');
export const NeedOrdersContractorSendEmailOwn = new Need('orders.contractor.send.email.own');
export const NeedOrdersSync = new Need('orders.sync');
export const NeedOrdersSyncOwn = new Need('orders.sync.own');
export const NeedOrdersItemsView = new Need('orders.items.view');
export const NeedOrdersItemsViewOwn = new Need('orders.items.view.own');
export const NeedOrdersItemsViewPrice = new Need('orders.items.view.price');
export const NeedOrdersItemsViewPriceOwn = new Need('orders.items.view.price.own');
export const NeedOrdersItemsAdd = new Need('orders.items.add');
export const NeedOrdersItemsAddOwn = new Need('orders.items.add.own');
export const NeedOrdersItemsEdit = new Need('orders.items.edit');
export const NeedOrdersItemsEditOwn = new Need('orders.items.edit.own');
export const NeedOrdersItemsEditPriceImported = new Need('orders.items.edit.price.imported');
export const NeedOrdersItemsEditPriceImportedOwn = new Need('orders.items.edit.price.imported.own');
export const NeedOrdersItemsEditPriceCustom = new Need('orders.items.edit.price.custom');
export const NeedOrdersItemsEditPriceCustomOwn = new Need('orders.items.edit.price.custom.own');
export const NeedOrdersItemsDelete = new Need('orders.items.delete');
export const NeedOrdersItemsDeleteOwn = new Need('orders.items.delete.own');
export const NeedOrdersDocumentsView = new Need('orders.documents.view');
export const NeedOrdersDocumentsViewOwn = new Need('orders.documents.view.own');
export const NeedOrdersDocumentsManage = new Need('orders.documents.manage');
export const NeedOrdersDocumentsManageOwn = new Need('orders.documents.manage.own');
export const NeedOrdersPaymentsView = new Need('orders.payments.view');
export const NeedOrdersPaymentsViewOwn = new Need('orders.payments.view.own');
export const NeedOrdersPaymentsManage = new Need('orders.payments.manage');
export const NeedOrdersPaymentsManageOwn = new Need('orders.payments.manage.own');
export const NeedOrdersRealisationsView = new Need('orders.realisations.view');
export const NeedOrdersTasksView = new Need('orders.tasks.view');
export const NeedOrdersTasksManage = new Need('orders.tasks.manage');
export const NeedOrdersClaimsView = new Need('orders.claims.view');
export const NeedOrdersClaimsManage = new Need('orders.claims.manage');
export const NeedOrdersHistoryView = new Need('orders.history.view');
export const NeedOrdersHistoryViewOwn = new Need('orders.history.view.own');
export const NeedOrdersCommentsView = new Need('orders.comments.view');
export const NeedOrdersCommentsViewOwn = new Need('orders.comments.view.own');
export const NeedOrdersCommentsManage = new Need('orders.comments.manage');
export const NeedOrdersCommentsManageOwn = new Need('orders.comments.manage.own');
export const NeedOrdersCommentsAdd = new Need('orders.comments.add');
export const NeedOrdersCommentsAddOwn = new Need('orders.comments.add.own');
