export default {
    'title': {
        ru: 'Реализации',
        en: 'Realisations',
    },
    'searchPlaceholder': {
        ru: 'Поиск реализации',
        en: 'Search realisations',
    },
    'selectContractorPlaceholder': {
        ru: 'Контрагент',
        en: 'Contractor',
    },
    'selectManagerPlaceholder': {
        ru: 'Ответственный',
        en: 'Responsible',
    },
    'selectDepartmentPlaceholder': {
        ru: 'Подразделение',
        en: 'Department',
    },
    'dateRangeFrom': {
        ru: 'С',
        en: 'From',
    },
    'dateRangeTo': {
        ru: 'По',
        en: 'To',
    },
    'dateRangeToday': {
        ru: 'Сегодня',
        en: 'Today',
    },
    'dateRangePrevMonth': {
        ru: 'Прошлый месяц',
        en: 'Previous month',
    },
    'dateRangeThisMonth': {
        ru: 'Текущий месяц',
        en: 'Current month',
    },
    'number': {
        ru: 'Номер',
        en: 'Number',
    },
    'orderNumber': {
        ru: 'Заказ',
        en: 'Order',
    },
    'orderDate': {
        ru: 'Дата заказа',
        en: 'Order date',
    },
    'contractor': {
        ru: 'Контрагент',
        en: 'Contractor',
    },
    'date': {
        ru: 'Дата',
        en: 'Date',
    },
    'netValue': {
        ru: 'Сумма без НДС',
        en: 'Value w/o VAT',
    },
    'vat': {
        ru: 'НДС',
        en: 'VAT',
    },
    'totalValue': {
        ru: 'Сумма с НДС',
        en: 'Value with VAT',
    },
}