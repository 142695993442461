//import libs
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';
import BreadCrumbs from "./BreadCrumbs";


class PageHeader extends Component {
  // set name of the component
  static displayName = 'Page header';

  static defaultProps = {
    breadcrumbs: []
  };

  // render component
  render() {
    const {title, breadcrumbs} = this.props;
    if (!title && !breadcrumbs.length)
      return null
    return (
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              {title &&
              <div className="page-header-title">
                <h5 className="m-b-10">{title}</h5>
              </div>
              }
              <BreadCrumbs breadcrumbs={breadcrumbs}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PageHeader
