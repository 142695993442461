import dayjs from 'dayjs';
import Model from '../../../../utils/Model';
import validators from '../../../../utils/validators';
import { composeValidators } from '../../../../utils/validators/compose';

class ContractorIndividualPassportModel extends Model {
    static validators = {
        firstName: composeValidators(validators.required, validators.word),
        lastName: composeValidators(validators.required, validators.word),
        middleName: composeValidators(validators.word),
        issuedAt: composeValidators(validators.date),
        series: composeValidators(validators.passport.series),
        number: composeValidators(validators.passport.number),
        inn: composeValidators(validators.bank.inn.physical, validators.required),
        ogrnip: composeValidators(validators.bank.ogrnip),
    };

    static warnings = {};

    constructor(props, legal) {
        super(props);
        this.legal = legal;
    }

    initialize(props) {
        super.initialize(props);
        this.id = props.id || null;
        this.inn = props.inn || '';
        this.ogrnip = props.ogrnip || '';
        this.firstName = props.firstName || '';
        this.middleName = props.middleName || '';
        this.lastName = props.lastName || '';
        this.series = props.series || '';
        this.number = props.number || '';
        this.issuedBy = props.issuedBy || '';
        this.issuedAt = props.issuedAt ? dayjs(props.issuedAt) : null;
        this.code = props.code || '';
        this.address = props.address || '';
    }

    getPassportNumber() {
        return this.series + ' ' + this.number;
    }

    getPassportIssuer() {
        return (this.issuedAt ? this.issuedAt.format('DD.MM.YYYY') : '') + ' ' + this.issuedBy;
    }
}

export default ContractorIndividualPassportModel;
