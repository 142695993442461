import loadable from '@react-loadable/revised';
import LoadingComponent from 'common/loader';
import type { ApplicationRoute } from 'routes/routes';

const routes: ApplicationRoute[] = [
    {
        path: '/tasks',
        exact: true,
        auth: true,
        component: loadable({
            loader: () => import('./pages/task-list/TaskListPage').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
    {
        path: '/task/:id',
        exact: true,
        auth: true,
        component: loadable({
            loader: () => import('./pages/view/TaskViewPage').then((m) => m.default),
            loading: LoadingComponent,
        }),
    },
];
export default routes;
