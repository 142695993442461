export default {
    'edit': {
        ru: 'Редактировать',
        en: 'Edit',
    },
    'add': {
        ru: 'Добавить категорию',
        en: 'Add category',
    },
    'addChild': {
        ru: 'Добавить дочернюю категорию',
        en: 'Add child category',
    },
    'delete': {
        ru: 'Удалить',
        en: 'Delete',
    },
    'edition': {
        ru: 'Редактирование категории',
        en: 'Category edit',
    },
    'creation': {
        ru: 'Создание категории',
        en: 'Category create',
    },
    'attachOrder': {
        ru: 'Привязать заказ',
        en: 'Attach order',
    },
    'detachOrder': {
        ru: 'Отвязать заказ',
        en: 'Detach order',
    },
    'confirmDetachOrder': {
        ru: 'Отвязать заказ?',
        en: 'Detach order?',
    },
    'confirmDelete': {
        ru: 'Удалить категорию?',
        en: 'Delete category?',
    },
    'insertBefore': {
        ru: (c) => `Вставить перед ${c}`,
        en: (c) => `Insert before ${c}`,
    },
    'insertAfter': {
        ru: (c) => `Вставить после ${c}`,
        en: (c) => `Insert after ${c}`,
    },
    'insertAsChild': {
        ru: (c) => `Сделать дочерней для ${c}`,
        en: (c) => `Insert as child of ${c}`,
    },
}