export default {
    'title': {
        ru: 'Проект',
        en: 'Project',
    },
    'attach': {
        ru: 'Привязать существующий',
        en: 'Attach existing',
    },
    'empty': {
        ru: 'Заказ не связан с проектом',
        en: 'Project not attached',
    },
    'createNew': {
        ru: 'Создать новый',
        en: 'Create new',
    },
    'attaching': {
        ru: 'Привязка к проекту',
        en: 'Attach to project',
    },
    'project': {
        ru: 'Проект',
        en: 'Project',
    },
    'category': {
        ru: 'Категория',
        en: 'Category',
    },
    'selectProjectFirst': {
        ru: 'Сначала выберите проект',
        en: 'Choose project first',
    },
}