import Http from '../../utils/Http';
import * as authActions from './store/actions';
import Transformer from '../../utils/Transformer';

/**
 * fetch the current logged in user
 *
 * @returns {function(*)}
 */
export function fetchUser() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('/me?expand=contractor')
                .then((res) => Transformer.fetch(res.data))
                .catch((err) => {
                    console.log(err);
                    return reject(err);
                })
                .then((data) => dispatch(authActions.authUser(data)));
        });
}

export function fetchPermissions() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.get('/me/permissions')
                .then((res) => Transformer.fetch(res.data))
                .catch((err) => {
                    console.log(err);
                    return reject(err);
                })
                .then((data) => dispatch(authActions.authPermissions(data)));
        });
}

export function login(credentials) {
    return new Promise((resolve, reject) => {
        Http.post('auth/login', credentials)
            .then((res) => {
                const data = Transformer.fetch(res.data);
                return resolve([data.accessToken, data.refreshToken]);
            })
            .catch((err) => {
                return reject(Transformer.error(err));
            });
    });
}

/**
 * login user
 *
 * @param token
 * @returns Promise
 */
export function loginByToken(token) {
    return new Promise((resolve, reject) => {
        Http.post('auth/login/' + token)
            .then((res) => Transformer.fetch([res.data.accessToken, res.data.refreshToken]))
            .catch((err) => reject(Transformer.error(err)));
    });
}

export function remindPassword(data) {
    return new Promise((resolve, reject) => {
        Http.post('auth/remind', Transformer.send(data))
            .then((res) => resolve(Transformer.fetch(res.data)))
            .catch((err) => reject(Transformer.error(err)));
    });
}

export function verifyEmailToken(id, token) {
    return new Promise((resolve, reject) => {
        Http.post('auth/verify-email-token', { token: token, id: id })
            .then((res) => resolve(Transformer.fetch(res.data)))
            .catch((err) => reject(Transformer.error(err)));
    });
}

export function verifyRemindToken(id, token) {
    return new Promise((resolve, reject) => {
        Http.post('auth/verify-remind-token', { token: token, id: id })
            .then((res) => resolve(Transformer.fetch(res.data)))
            .catch((err) => reject(Transformer.error(err)));
    });
}

/**
 * Set Password
 *
 * @returns {function(*)}
 */
export function setPassword(data) {
    return new Promise((resolve, reject) => {
        Http.post('/auth/set-password', Transformer.send(data))
            .then((res) => resolve(Transformer.fetch(res.data)))
            .catch((err) => reject(Transformer.error(err)));
    });
}
/**
 * Reset Password
 *
 * @returns {function(*)}
 */
export function resetPassword(data) {
    return new Promise((resolve, reject) => {
        Http.post('/auth/reset-password', Transformer.send(data))
            .then((res) => resolve(Transformer.fetch(res.data)))
            .catch((err) => reject(Transformer.error(err)));
    });
}

/**
 * Refresh access token
 *
 * @param refreshToken
 * @returns {function(*)}
 */
export function refreshToken(refreshToken) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post('auth/refresh', { refreshToken: refreshToken })
                .then((res) => {
                    const data = Transformer.fetch(res.data);
                    dispatch(authActions.authRefreshToken(data.accessToken, data.refreshToken));
                    return resolve(data.accessToken);
                })
                .catch((err) => {
                    console.log(err);
                    return reject(err);
                });
        });
}

/**
 * logout user
 *
 * @returns {function(*)}
 */
export function localLogout() {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            dispatch(authActions.authLogout());
            return resolve();
        });
}
/**
 * logout user
 *
 * @returns {function(*)}
 */
export function logout(accessToken, refreshToken) {
    return (dispatch) =>
        new Promise((resolve, reject) => {
            Http.post('auth/logout', { accessToken, refreshToken })
                .then((data) => {
                    dispatch(authActions.authLogout());
                    return resolve();
                })
                .catch((err) => {
                    console.log(err);
                    return reject(err);
                });
        });
}
