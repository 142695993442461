import sections from './sections';
import synchronization from './synchronization';
import orders from './orders';
import managers from './managers';
import items from './items';
import attachments from './attachments'
import categories from './categories'


export default {
    sections,
    synchronization,
    orders,
    managers,
    items,
    attachments,
    categories,
    'projects': {
        ru: 'Проекты',
        en: 'Projects',
    },
    'project': {
        ru: 'Проект',
        en: 'Project',
    },
    'projectCard': {
        ru: 'Карточка проекта',
        en: 'Project card',
    },
    'searchPlaceholder': {
        ru: 'Поиск проекта...',
        en: 'Project search...',
    },
    'searchHint': {
        ru: 'Поиск по названию, номеру заказа, фио менеджера',
        en: 'Search order by name, number or manager',
    },
    'general': {
        ru: 'Основное',
        en: 'General',
    },
    'finances': {
        ru: 'Финансы',
        en: 'Finances',
    },
    'name': {
        ru: 'Название',
        en: 'Name',
    },
    'ordersField': {
        ru: 'Заказы',
        en: 'Orders',
    },
    'managersField': {
        ru: 'Менеджеры',
        en: 'Managers',
    },
    'totalValue': {
        ru: 'Сумма',
        en: 'Total value',
    },
    'comment': {
        ru: 'Комментарий к проекту',
        en: 'Comment',
    },
    'createTitle': {
        ru: 'Создание проекта',
        en: 'Create new project',
    },
    'attachOrder': {
        ru: 'Привязать заказ',
        en: 'Attach order',
    },
}