export default {
    cabinet: {
        ru: (name) => `Кабинет дилера ${name}`,
        en: (name) => `Dealer's cabinet ${name}`,
    },
    orderCreation: {
        ru: 'Создание заказа',
        en: 'Create new order',
    },
    ordersList: {
        ru: 'Список заказов',
        en: 'Orders list',
    },
    calculate: {
        ru: 'Рассчитать',
        en: 'Calculate',
    },
    calculating: {
        ru: 'Рассчитываем...',
        en: 'Calculating...',
    },
    orderView: {
        ru: 'Просмотр расчёта',
        en: 'Calculation details',
    },
    calculationInProgress: {
        ru: 'Идёт рассчёт стоимости...',
        en: 'Price calculation is in progress...',
    },
    calculationContent: {
        ru: 'Состав расчёта',
        en: 'Calculation content',
    },
    submit: {
        ru: 'Оформить расчёт',
        en: 'Submit calculation',
    },
    submitting: {
        ru: 'Оформление...',
        en: 'Submitting...',
    },
    successfullySubmitted: {
        ru: 'Расчёт оформлен успешно',
        en: 'Calculation successfully submitted',
    },
};
