import Model from 'utils/Model';
import ReportFileModel from "./ReportFileModel";



class KitFileModel extends Model {

  static validators = {
  };

  initialize(props) {
    super.initialize(props);
    this.orderId = props.orderId || null;
    this.parentId = props.parentId || null;
    this.size = props.size || 0;
    this.reportFiles = props.reportFiles && props.reportFiles.length ? props.reportFiles.map(item => new ReportFileModel(item)) : [];
  }

  setReports(reports) {
    this.reportFiles = reports.map(r => new ReportFileModel(r));
  }

  getDate() {
    return this.createdAt ? this.createdAt.format('DD MMM YYYY HH:mm') : '';
  }

  hasReports() {
    return this.reportFiles.length > 0;
  }

  getEolinkUrl(orderId = this.orderId) {
    const id = this.id || 0;
    return `eolink://openkit-${orderId}-${id}`;
  }

  getRegenerateReportsUrl(orderId = this.orderId) {
    const id = this.id || 0;
    return `eolink://reports-${orderId}-${id}`;
  }

}

export default KitFileModel
