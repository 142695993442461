// import libs
import { connect } from 'react-redux';

// import components
import Header from './Header';
import UserModel from "modules/users/models/UserModel";

const mapStateToProps = state => ({
  menuOpened: state.layout.navBar.mobileOpened,
  user: state.auth.user ? new UserModel(state.auth.user) : state.auth.user
});

export default connect(mapStateToProps)(Header);