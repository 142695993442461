import axios from 'axios';
import config from '../config/config';

export function toggleDebug() {
    if (isDebugAvailable()) {
        localStorage.setItem('debug', isDebugEnabled() ? '0' : '1');
        setAxiosTimeout(config.apiTimeout);
    }

    return isDebugEnabled();
}
export function isDebugEnabled() {
    if (!isDebugAvailable()) {
        return false;
    }
    return localStorage.getItem('debug') === '1';
}

export function setAxiosTimeout(timeout) {
    axios.defaults.timeout = isDebugEnabled() ? 300000 : timeout;
}

export function isDebugAvailable() {
    return process.env.NODE_ENV === 'development';
}
