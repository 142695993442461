export default {
    addAttachment: {
        ru: 'Добавить вложение',
        en: 'Add attachment',
    },
    createDocument: {
        ru: 'Создать документ',
        en: 'Create document',
    },
    documents: {
        ru: 'Документы',
        en: 'Documents',
    },
    documentsEmptyList: {
        ru: 'Документы еще не созданы',
        en: 'Documents are not created yet',
    },
    documentDeleteConfirmation: {
        ru: 'Вы уверены, что хотите удалить документ?',
        en: 'Are you sure want to delete document?',
    },
    noDocumentTemplates: {
        ru: 'Нет доступных шаблонов',
        en: 'No available templates',
    },
    addDocument: {
        ru: 'Создать документ',
        en: 'Create new document',
    },
    reports: {
        ru: 'Выходные документы',
        en: 'Reports',
    },
    reportsEmptyList: {
        ru: 'Отчёты еще не добавлены',
        en: 'Reports are not attached yet',
    },
    attachments: {
        ru: 'Вложения',
        en: 'Attachments',
    },
    attachmentsEmptyList: {
        ru: 'Вложения еще не добавлены',
        en: 'Attachments are not attached yet',
    },
    attachmentCreation: {
        ru: 'Загрузка вложения',
        en: 'Attachment upload',
    },
    attachmentEdition: {
        ru: 'Редактирование вложения',
        en: 'Attachment edition',
    },
    attachmentDeleteConfirmation: {
        ru: 'Вы уверены, что хотите удалить вложение?',
        en: 'Are you sure want to delete attachment?',
    },
    reportDeleteConfirmation: {
        ru: 'Вы уверены, что хотите удалить отчёт?',
        en: 'Are you sure want to delete report?',
    },
    attachmentName: {
        ru: 'Название',
        en: 'Name',
    },
    attachmentNameHint: {
        ru: 'Оставьте пустым для использования имени файла',
        en: 'Leave it blank for use original file name',
    },
    attachmentFile: {
        ru: 'Файл',
        en: 'File',
    },
    attachmentFileHint: {
        ru: 'Максимум 5 Мб',
        en: '5 MB maximum',
    },
    name: {
        ru: 'Название',
        en: 'Name',
    },
    size: {
        ru: 'Размер',
        en: 'Size',
    },
    added: {
        ru: 'Добавлен',
        en: 'Added',
    },
    viewParameters: {
        ru: 'Просмотр параметров',
        en: 'View parameters',
    },
};
