import ProjectOrderModel from "modules/projects/models/orders/ProjectOrderModel";
import validators from "utils/validators";
import Model from 'utils/Model'
import {composeValidators} from "utils/validators/compose";

class ProjectCategoryModel extends Model {

    static validators = {
        name: composeValidators(validators.required),
    };

    initialize(props) {
        super.initialize(props);
        this.name = props.name;
        this.setChildren(props.children);
        this.setOrders(props.orders);
    }

    setChildren(children) {
        this.children = [];
        if(!Array.isArray(children)) return;
        this.children = children.map(c => c instanceof ProjectCategoryModel ? c : new ProjectCategoryModel(c));
    }

    setOrders(orders) {
        this.orders = [];
        if(!Array.isArray(orders)) return;
        this.orders = orders.map(o => o instanceof ProjectOrderModel ? o : new ProjectOrderModel(o));
    }

}

export default ProjectCategoryModel
