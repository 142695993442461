// import libs
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { navbarCollapse, navbarExpand } from './store/actions';

// import components
import Desktop from '../header/components/brand/Desktop';
import Navigation from './components/navigation/Navigation';
import getItems from './menu-items';

// initialize component
class NavBar extends Component {
    // set name of the component
    static displayName = 'Navigation panel';

    // validate props
    static propTypes = {
        collapsed: PropTypes.bool.isRequired,
        mobileOpened: PropTypes.bool.isRequired,
        dispatch: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        // bind component with event handlers
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    toggleCollapse() {
        const { dispatch, collapsed } = this.props;
        collapsed ? dispatch(navbarExpand()) : dispatch(navbarCollapse());
    }

    // render component
    render() {
        const { collapsed, mobileOpened, config } = this.props;
        const cls = classNames(
            'pcoded-navbar',
            'menupos-static',
            { 'navbar-collapsed': collapsed },
            { 'mob-open': mobileOpened }
        );

        return (
            <nav className={cls}>
                <div className="navbar-wrapper">
                    <Desktop onCollapseClick={this.toggleCollapse} collapsed={collapsed} />
                    <div className="navbar-content scroll-div main-navigation">
                        <Navigation items={getItems(config)} />
                    </div>
                </div>
            </nav>
        );
    }
}

export default NavBar;
