import dayjs from 'dayjs';
import { t } from '../../../translations';
import Model from '../../../utils/Model';
import validators from '../../../utils/validators';
import { composeValidators } from '../../../utils/validators/compose';

class MailboxModel extends Model {
    static validators = {
        email: composeValidators(validators.required),
        login: composeValidators(validators.required),
        password: composeValidators(validators.required),
        income: {
            type: composeValidators(validators.required),
            host: composeValidators(validators.required),
            port: composeValidators(
                validators.required,
                validators.minNumber(0),
                validators.maxNumber(65535)
            ),
        },
        outcome: {
            host: composeValidators(validators.required),
            port: composeValidators(
                validators.required,
                validators.minNumber(0),
                validators.maxNumber(65535)
            ),
        },
    };

    constructor(props) {
        super(props);
    }

    initialize(props) {
        super.initialize(props);

        this.id = props.id || null;
        this.isActive = props.isActive !== undefined ? !!props.isActive : null;
        this.email = props.email || '';
        this.login = props.login || '';
        this.income = {
            type: props.income.type || null,
            host: props.income.host || '',
            port: props.income.port || '',
            ssl: props.income.ssl || 'none',
        };
        this.outcome = {
            host: props.outcome.host || '',
            port: props.outcome.port || '',
            ssl: props.outcome.ssl || 'none',
        };

        this.lastCheckedAt = props.lastCheckedAt ? dayjs(props.lastCheckedAt) : null;

        this.lastCheckError = props.lastCheckError || null;

        this.incomeAvailable = props.incomeAvailable || false;
        this.outcomeAvailable = props.outcomeAvailable || false;
        this.incomeDirectories = props.incomeDirectories || [];
    }

    getLastCheckedDate() {
        if (this.lastCheckedAt === null) return t('common.never');

        return this.lastCheckedAt.fromNow();
    }
}

export default MailboxModel;
