export default {
    searchRegion: {
        en: 'Search region...',
        ru: 'Поиск региона...',
    },
    name: {
        en: 'Name',
        ru: 'Название',
    },
    saved: {
        en: 'Region saved',
        ru: 'Регион сохранён',
    },
    created: {
        en: 'Region created',
        ru: 'Регион создан',
    },
};
