export default {
    enable: {
        ru: 'Включить уведомления',
        en: 'Enable notifications',
    },
    pleaseEnable: {
        ru: 'Разрешите уведомления',
        en: 'Enable notifications',
    },
    popupDescriptions: {
        ru: 'Пожалуйста, включите уведомления. Нажмите на кнопку "Включить уведомления", затем "Разрешить" во всплывающем окне',
        en: 'Please enable notifications. Click on "Enable notifications" button and then press "Allow" in browser pop-up',
    },
};
