export default {
    'title': {
        ru: 'Контакты',
        en: 'Contacts',
    },
    'addition': {
        ru: 'Добавление контакта',
        en: 'Add contact',
    },
    'edition': {
        ru: 'Редактирование контакта',
        en: 'Edit contact',
    },
    'emptyList': {
        ru: 'Контактов нет',
        en: 'No contacts',
    },
    'deletionConfirm': {
        ru: 'Вы уверены, что хотите удалить контакт?',
        en: 'Are you sure you want to delete contact?',
    },
    'name': {
        ru: 'Имя',
        en: 'Name',
    },
    'rank': {
        ru: 'Должность',
        en: 'Rank',
    },
    'comment': {
        ru: 'Комментарий',
        en: 'Comment',
    },
    'phone': {
        ru: 'Телефон',
        en: 'Phone',
    },
    'email': {
        ru: 'EMail',
        en: 'EMail',
    },
    'site': {
        ru: 'Сайт',
        en: 'Web-site',
    },
    'communications': {
        ru: 'Средства связи',
        en: 'Communications',
    },
    'addCommunication': {
        ru: 'Добавить',
        en: 'Add',
    },
}