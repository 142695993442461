import errors from './common/errors';
import time from './common/time';

export default {
    time,
    errors,
    dashboard: {
        ru: 'Ситуация',
        en: 'Dashboard',
    },
    eolinkWelcome: {
        ru: 'Для полноценной работы с конфигуратором Design и дополнительными функциями Вам необходимо скачать и установить EOLink',
        en: 'For integration with 20-20 Design and another functions you must download and install EOLink app',
    },
    eolinkApiUrlRequest: {
        ru: 'После установки вам понадобится ввести адрес API. Используйте данный адрес:',
        en: 'After installation you might be asked for api url. Use next address:',
    },
    saving: {
        ru: 'Сохранение...',
        en: 'Saving...',
    },
    saved: {
        ru: 'Сохранено',
        en: 'Saved',
    },
    savingError: {
        ru: 'Ошибка сохранения',
        en: 'Saving error',
    },
    loading: {
        ru: 'Загрузка...',
        en: 'Loading...',
    },
    creating: {
        ru: 'Создается...',
        en: 'Creating...',
    },
    loadingPleaseWait: {
        ru: 'Подождите немного',
        en: 'Wait a bit',
    },
    loadingError: {
        ru: 'Ошибка загрузки',
        en: 'Error while loading',
    },
    loadMore: {
        ru: 'Загрузить ещё',
        en: 'Load more',
    },
    empty: {
        ru: 'Список пуст',
        en: 'Empty list',
    },
    error: {
        ru: 'Ошибка',
        en: 'Error',
    },
    removingError: {
        ru: 'Ошибка удаления',
        en: 'Error occurred while removing',
    },
    nothingFound: {
        ru: 'Ничего не найдено',
        en: 'Nothing found',
    },
    noData: {
        ru: 'Нет данных',
        en: 'No data',
    },
    recentOrders: {
        ru: 'Недавние заказы',
        en: 'Recent orders',
    },
    settings: {
        ru: 'Настройки',
        en: 'Settings',
    },
    profile: {
        ru: 'Профиль',
        en: 'Profile',
    },
    messages: {
        ru: 'Сообщения',
        en: 'Messages',
    },
    never: {
        ru: 'Никогда',
        en: 'Never',
    },
    notSet: {
        ru: (type) => {
            switch (type) {
                case 1:
                    return 'не задан'; //Male
                case 2:
                    return 'не задана'; //Female
                case 3:
                    return 'не заданы'; //Multiple
                default:
                    return 'не задано';
            }
        },
        en: 'not set',
    },
    invalidSection: {
        ru: 'Неверная секция',
        en: 'Invalid section',
    },
    fileUploadClickOrDrag: {
        ru: 'Нажмите или перетащите файл для загрузки',
        en: "Click or drag'n'drop files",
    },
    fileUploadSupportedTypes: {
        ru: 'Поддерживаются файлы типов:',
        en: 'Supported types:',
    },
    edited: {
        ru: (type) => {
            switch (type) {
                case 1:
                    return 'отредатирован'; //Male
                case 2:
                    return 'отредактирована'; //Female
                case 3:
                    return 'отредактированы'; //Multiple
                default:
                    return 'отредактировано';
            }
        },
        en: 'edited',
    },
    apiRequestError: {
        ru: 'Ошибка запроса к API',
        en: 'API request error',
    },
    forbidden: {
        ru: 'Доступ запрещён',
        en: 'Access denied',
    },
    pageNotFound: {
        ru: 'Страница не найдена',
        en: 'PageNotFound',
    },
    search: {
        ru: 'Поиск',
        en: 'Search',
    },
    male: {
        ru: 'Мужчина',
        en: 'Male',
    },
    female: {
        ru: 'Женщина',
        en: 'Female',
    },
    manager: {
        ru: 'Менеджер',
        en: 'Manager',
    },
    technologist: {
        ru: 'Технолог',
        en: 'Technologist',
    },
    scheduler: {
        ru: 'Планировщик',
        en: 'Scheduler',
    },
    admin: {
        ru: 'Администратор',
        en: 'Administrator',
    },
    groupBy: {
        ru: (name) => `Группировать по: ${name}`,
        en: (name) => `Group by ${name}`,
    },
    statistics: {
        ru: 'Статистика',
        en: 'Statistics',
    },
    selectAll: {
        ru: 'Выбрать все',
        en: 'Select all',
    },
    deselectAll: {
        ru: 'Очистить все',
        en: 'Deselect all',
    },
    close: {
        en: 'Close',
        ru: 'Закрыть',
    },
    reset: {
        en: 'Reset',
        ru: 'Сбросить',
    },
};
