import OrderModel from "modules/orders/models/OrderModel";
import ProjectModel from "modules/projects/models/ProjectModel";
import Model from 'utils/Model'

class OrderProjectModel extends Model {

    initialize(props) {
        super.initialize(props);
        this.orderId = props.orderId;
        this.projectId = props.projectId || null;
        this.project = new ProjectModel(props.project || {});
        this.comment = props.comment || '';
    }

    getProjectUrl() {
        return this.project.getUrl();
    }
}

export default OrderProjectModel
