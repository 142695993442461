import Model from '../../../../utils/Model'


class OrderAvailableStatusModel extends Model {

  static validators = {
  };

  initialize(props) {
    super.initialize(props);
    this.caption = props.caption || '';
    this.style = props.style || '';
    this.valid = !!props.valid;
    this.messages = props.messages || [];
    this.prompt = props.prompt || null;

  }
}

export default OrderAvailableStatusModel
