import { default as classNames } from 'classnames';
import { FC } from 'react';

interface SpinnerProps {
    caption?: string;
    color?: string;
    small?: boolean;
    size?: number;
    className?: string;
}

const Spinner: FC<SpinnerProps> = ({ caption, color, small, size, className = '' }) => {
    const cls = classNames('spinner-grow', color, { 'spinner-grow-sm': small });

    let style = {};

    if (size) {
        style = {
            height: size,
            width: size,
        };
    }

    return (
        <div className={className}>
            <span className={cls} style={style} />
            {caption && ' ' + caption}
        </div>
    );
};
export default Spinner;
