import Model from 'utils/Model'
import {composeValidators} from "utils/validators/compose";
import validators from "utils/validators";
import UserModel from "../../../users/models/UserModel";



class ManagerModel extends Model {

  static validators = {
    userId: composeValidators(validators.integer, validators.required),
  };

  initialize(props) {
    super.initialize(props);
    this.contractorId = props.contractorId;
    this.userId = props.userId || null;
    this.user = new UserModel(props.user || {});
    this.comment = props.comment || '';
  }


}

export default ManagerModel
