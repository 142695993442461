import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchPermissions, fetchUser } from '../modules/auth/service';
import UserModel from '../modules/users/models/UserModel';
import withRouter from '../utils/hocs/withRouter';
import { AppConfigContext } from './AppConfigContext';
import DealerCabinetLayout from './DealerCabinetLayout';
import GlobalLoader from './GlobalLoader';
import PrivateLayout from './Private';
import PublicLayout from './Public';

const Layout = (props) => {
    const { isAuthenticated, children, dispatch, user } = props;
    const config = useContext(AppConfigContext);

    useEffect(() => {
        if (isAuthenticated && !user) {
            dispatch(fetchUser());
            dispatch(fetchPermissions());
        }
    }, [isAuthenticated, user]);

    if (isAuthenticated) {
        if (!user) {
            return <GlobalLoader />;
        }
        if (config.dealerCabinet?.enabled && user?.isFeatureEnabled(4)) {
            return <DealerCabinetLayout {...props}>{children}</DealerCabinetLayout>;
        }
        return <PrivateLayout {...props}>{children}</PrivateLayout>;
    }

    return <PublicLayout {...props}>{children}</PublicLayout>;
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user ? new UserModel(state.auth.user) : state.auth.user,
    };
};

export default withRouter(connect(mapStateToProps)(Layout));
