export default {
    'readMore': {
        ru: 'Подробнее...',
        en: 'Read more...',
    },
    'hide': {
        ru: 'Скрыть',
        en: 'Hide',
    },
    'from': {
        ru: 'От',
        en: 'From',
    },
    'to': {
        ru: 'Кому',
        en: 'To',
    },
    'copy': {
        ru: 'Копия',
        en: 'Copy',
    },
    'withAttachments': {
        ru: 'Есть вложения',
        en: 'With attachments',
    },
    'showText': {
        ru: 'Показать текст',
        en: 'Show as text',
    },
    'showHTML': {
        ru: 'Показать HTML',
        en: 'Show as HTML',
    },
}