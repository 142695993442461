import dayjs from 'dayjs';
import Model from 'utils/Model';
import OrderPaymentModel from '../../../orders/models/payments/OrderPaymentModel';

class PaymentModel extends Model {
    initialize(props) {
        super.initialize(props);
        this.contractorId = props.contractorId || null;
        this.externalId = props.externalId || null;
        this.type = props.type || null;
        this.number = props.number || null;
        this.isFullyUsed = props.isFullyUsed || null;
        this.date = (props.date && dayjs(props.date)) || null;
        this.valueFormatted = props.valueFormatted || null;
        this.unusedValueFormatted = props.unusedValueFormatted || null;
        this.orderPayments =
            props.orderPayments && props.orderPayments.length
                ? props.orderPayments.map((p) => new OrderPaymentModel(p))
                : [];
    }
}

export default PaymentModel;
