import classNames from 'classnames';
import { AppConfigContext } from 'layout/AppConfigContext';
import NotificationCounter from 'modules/events/components/NotificationCounter';
import { EventsService } from 'modules/events/service';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { t } from 'translations';
import useClickOutside from 'utils/hooks/useClickOutside';

export default function Notifications() {
    const cont = useRef();
    const [expanded, setExpanded] = useState(null);

    useClickOutside(cont, () => {
        if (expanded !== null) {
            setExpanded(false);
        }
    });
    const config = useContext(AppConfigContext);

    const enabled = config.remote.events.enabled ?? false;
    const [count, setCount] = React.useState(0);
    const [ordersCount, setOrdersCount] = React.useState(0);

    const fetchCount = () => {
        if (document.hidden) {
            return;
        }
        EventsService.fetchCount().then((data) => {
            setCount(data.count);
            setOrdersCount(data.ordersCount);
        });
    };

    useEffect(() => {
        if (!enabled) {
            return () => {};
        }
        fetchCount();
        const interval = setInterval(fetchCount, 15000);
        return () => clearInterval(interval);
    }, [setCount, enabled]);

    if (!enabled) {
        return null;
    }

    const menuCls = classNames(
        'dropdown-menu dropdown-menu-right profile-notification animated',
        { bounceIn: expanded === true },
        { bounceOut: expanded === false },
        { 'display-none': expanded === null }
    );

    return (
        <li>
            <div className="dropdown" style={{ position: 'relative' }} ref={cont}>
                <a className="cr-pointer" onClick={() => setExpanded(!expanded)}>
                    <i className="icon fa fa-bell" />
                    <NotificationCounter count={count} />
                </a>

                <div className={menuCls} style={{ right: -45 }}>
                    <div className="pro-head">
                        <span>{t('events.events')}</span>
                    </div>
                    <ul
                        className="pro-body"
                        style={{
                            backgroundColor: 'white',
                            border: '1px solid lightgray',
                            borderTop: 'none',
                        }}
                    >
                        <li>
                            <Link to="/" className="dropdown-item">
                                {t('events.orders')} {ordersCount > 0 && '[' + ordersCount + ']'}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    );
}
