import mailing from './mailing';

export default {
    mailing,
    'title': {
        ru: 'Контрагент',
        en: 'Contractor',
    },
    'sent': {
        ru: 'Письмо отправленно',
        en: 'Email was sent',
    },

}